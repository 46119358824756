const publicPath = process.env.BASE_URL

const FEATURES = {
  lav4all: {
    label: 'LAV4ALL',
    description: 'The aircraft lavatory anyone can use without assistance.',
    video: {
      source: publicPath + 'video/lav4all.mp4',
      poster: publicPath + 'img/features/lav4all.jpg'
    },
    images: false,
    moreDescription: 'To increase the feeling of privacy and dignity of passengers with reduced mobility and travel comfort in general, FACC has developed a new lavatory solution, using hygienic surfaces with seamless design: LAV4ALL with 100% accessibility and more free moving space inside to the same external dimensions.'
  },
  partition: {
    label: 'Cabin Divider',
    description: 'Design places for every aspect of lives, from leisure to work.',
    video: {
      source: publicPath + 'video/partition.mp4',
      poster: publicPath + 'img/features/partition.jpg'
    },
    moreDescription: 'As a final element, our cabin divider can be integrated holistically into the cabin design by using decorative foils, paintwork with airline-typical colors or logos, or even exclusive materials and additional screens. The new transparency and dimming function allows a new onboard experience.'
  },
  moveableDivider: {
    label: 'Moveable Class Divider',
    description: 'The greatest balance between passenger comfort, maximum space saving and the ability to quickly change your configuration.',
    video: {
      source: publicPath + 'video/moveable-divider.mp4',
      poster: publicPath + 'img/features/moveable-divider.jpg'
    },
    moreDescription: 'Reconfigure your cabin according to your needs. Our flexible solutions adapt perfectly to the Hatracks and convince with the simplest handling. Customisable surfaces and additional features such as integrated screens connect the digital with the physical world.'
  },
  centerCeilingStowage: {
    label: 'Center Ceiling Stowage+',
    description: 'Center Ceiling Stowage+ fits seamlessly into existing designs and impresses with more storage space, flexible positioning and cost efficiency.',
    video: {
      source: publicPath + 'video/center-ceiling-stowage.mp4',
      poster: publicPath + 'img/features/center-ceiling-stowage.jpg'
    },
    moreDescription: 'Compact on the outside, surprisingly large on the inside. Centre Ceiling Stowage+ (CCS+) fits seamlessly into existing designs and impresses with more storage space, flexible positioning and cost efficiency.'
  },
  sidewallDecorCover: {
    label: 'Sidewall',
    description: 'From conventional designs, green and flocking materials, to exclusive materials, there is a wealth of options.',
    moreDescription: 'Adapt your space to different needs and create a special experience. Exclusive materials (wood veneer, leather, etc.) give your room a special flair and allow for the best possible differentiation.',
    colorsDetails: {
    //   blue: {
    //     label: 'Blue',
    //     colorCode: '#34779A',
    //     detailImage: publicPath + 'img/sidewalls/sidewall-blue.png'
    //   },
      chocolateleather: {
        label: 'Chocolate Leather',
        colorImage: publicPath + 'img/sidewalls/chocolateleather.png',
        detailImage: publicPath + 'img/sidewalls/chocolateleather-full.png'
      },
      orientalwood: {
        label: 'Oriental Wood',
        colorImage: publicPath + 'img/sidewalls/orientalwood.png',
        detailImage: publicPath + 'img/sidewalls/orientalwood-full.png'
      },
      oceanwood: {
        label: 'Ocean Wood',
        colorImage: publicPath + 'img/sidewalls/oceanwood.png',
        detailImage: publicPath + 'img/sidewalls/oceanwood-full.png'
      },
      foggywood: {
        label: 'Foggy Wood',
        colorImage: publicPath + 'img/sidewalls/foggywood.png',
        detailImage: publicPath + 'img/sidewalls/foggywood-full.png'
      },
      rawsperryleather: {
        label: 'Rawsperry Leather',
        colorImage: publicPath + 'img/sidewalls/rawsperryleather.png',
        detailImage: publicPath + 'img/sidewalls/rawsperryleather-full.png'
      },
      rosevelvet: {
        label: 'Rose Velvet',
        colorImage: publicPath + 'img/sidewalls/rosevelvet.png',
        detailImage: publicPath + 'img/sidewalls/rosevelvet-full.png'
      },
      custom: {
        label: 'Custom Color',
        colorImage: publicPath + 'img/sidewalls/custom.png'
      }
    }
  },
  stowages: {
    label: 'Stowages',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.'
  }
}

export default FEATURES
