<template>
  <div class="configurator" :class="{'is-visible': visible}">
    <div class="configurator__inner" :class="{'is-dimmed' : dimmed}">
        <feature-navigation :features="availableFeatures" :active-feature="activeFeature" :active-configuration="configuration" @featureClick="featureClickHandler" @resetClick="resetClickHandler" />
        <div class="configurator__main">
            <div class="configurator__visual">
                <div class="configurator__visual__inner">
                    <plane-blueprint :layout="fullActiveLayout.component" :active-configuration="configuration" :active-feature-slug="fullActiveFeature.slug" />
                </div>
                <help-button />
            </div>
            <div class="configurator__side container">
                <div class="configurator__side__inner" :class="{'is-hidden' : sideHidden}">
                    <div class="configurator__side__title h2">
                        {{ fullActiveFeature.label }}
                    </div>
                    <div class="configurator__side__text copy">
                        {{ fullActiveFeature.description }}
                    </div>

                    <div class="configurator__side__selector" v-if="fullActiveFeature.positions">
                        <div class="configurator__side__selector__label label">Select one or multiple:</div>
                        <multi-select :options="fullActiveFeature.positions" :initialValues="activeFeatureConfiguration.selectedPositions" @selectionChanged="positionSelectionChangedHandler" />
                    </div>

                    <div class="configurator__side__tabs" v-if="fullActiveFeature.classes && fullActiveFeature.classes.length > 1">
                        <type-switch class="type-switch--tabs" :options="fullActiveFeature.classes" :active-option="activeFeatureConfiguration.activeTab" @optionClick="tabClickHandler" />
                    </div>

                    <div class="configurator__side__selector" v-if="fullActiveFeature.colors">
                        <div class="configurator__side__selector__label label">Select one:</div>
                        <multi-select :options="fullActiveFeature.availableColors" :initialValues="activeFeatureConfiguration.selectedColors[activeFeatureConfiguration.activeTab]" :singleSelect="true" @selectionChanged="colorSelectionChangedHandler" :styling="'color'" />
                    </div>

                    <div class="configurator__side__content" v-if="fullActiveFeature.video || fullActiveFeature.images || fullActiveFeature.moreDescription">
                        <div class="configurator__side__content__item label">More information:</div>
                        <video-player class="configurator__side__content__item" v-if="fullActiveFeature.video" :video="fullActiveFeature.video"></video-player>
                            <div class="configurator__side__content__item copy" v-if="fullActiveFeature.moreDescription">
                            {{ fullActiveFeature.moreDescription }}
                        </div>
                    </div>
                </div>
                <div class="configurator__side__button">
                    <div class="button button--fullwidth" @click="toggleSummary">
                        Summary
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Transition name="slide-in-left">
      <summary-sidebar v-if="showSummary" :active-configuration="configuration" :available-features="availableFeatures" @toggle="toggleSummary" @goToFeature="goToFeatureHandler" @resetClick="resetClickHandler" @getLayoutClick="getLayoutClickHandler" />
    </Transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AIRCRAFT_TYPES from '@/data/aircraft-types.js'
import FEATURES from '@/data/features.js'
import FeatureNavigation from '@/components/FeatureNavigation.vue'
import MultiSelect from '@/components/MultiSelect.vue'
import SummarySidebar from '@/components/SummarySidebar.vue'
import VideoPlayer from '@/components/VideoPlayer.vue'
import PlaneBlueprint from '@/components/PlaneBlueprint.vue'
import HelpButton from '@/components/HelpButton.vue'
import TypeSwitch from '@/components/TypeSwitch.vue'

export default {
  name: 'Configurator',
  components: {
    FeatureNavigation,
    MultiSelect,
    SummarySidebar,
    VideoPlayer,
    PlaneBlueprint,
    HelpButton,
    TypeSwitch
  },
  data () {
    return {
      AIRCRAFT_TYPES,
      FEATURES,
      visible: false,
      dimmed: false,
      sideHidden: false,
      activeType: false,
      activeLayout: false,
      activeFeature: false,
      configuration: false,
      showSummary: false
    }
  },
  computed: {
    ...mapGetters(['activeConfiguration']),
    fullActiveType () {
      return this.AIRCRAFT_TYPES.find(type => type.slug === this.activeType)
    },
    fullActiveLayout () {
      return this.fullActiveType.layouts.find(layout => layout.slug === this.activeLayout)
    },
    fullActiveFeature () {
      const feature = this.availableFeatures.find(feature => feature.slug === this.activeFeature)
      // add available colors to separate array
      if (feature.colors && feature.colorsDetails) {
        feature.availableColors = []
        feature.colors.forEach(color => {
          if (feature.colorsDetails[color]) {
            feature.availableColors.push({ ...feature.colorsDetails[color], slug: color })
          }
        })
      }
      return feature
    },
    availableFeatures () {
      const features = []
      for (const key in this.fullActiveLayout.availableFeatures) {
        const fullFeature = { ...this.FEATURES[key], ...this.fullActiveLayout.availableFeatures[key] }
        fullFeature.slug = key
        features.push(fullFeature)
      }
      return features
    },
    activeFeatureConfiguration () {
      if (this.configuration && this.fullActiveFeature) {
        return this.configuration[this.fullActiveFeature.slug]
      }
      return false
    }
  },
  watch: {
    activeFeature () { // animate sidebar when switching between features
      this.sideHidden = true
      setTimeout(() => {
        this.sideHidden = false
      }, 10)
    }
  },
  created () {
    this.activeType = this.$route.params.type
    this.activeLayout = this.$route.params.layout
    this.activeFeature = this.availableFeatures[0].slug
    this.createConfigurationBlueprint()
  },
  mounted () {
    setTimeout(() => {
      this.visible = true
    }, 100)
  },
  methods: {
    featureClickHandler (e) {
      this.activeFeature = e
    },
    resetClickHandler () {
      this.activeFeature = this.availableFeatures[0].slug
      this.createConfigurationBlueprint()
    },
    positionSelectionChangedHandler (e) {
      this.configuration[this.fullActiveFeature.slug].selectedPositions = e
    },
    colorSelectionChangedHandler (e) {
      this.configuration[this.fullActiveFeature.slug].selectedColors[this.activeFeatureConfiguration.activeTab] = e
    },
    goToFeatureHandler (e) {
      this.activeFeature = e.slug
      if (e.tab) this.configuration[e.slug].activeTab = e.tab
      this.toggleSummary()
    },
    tabClickHandler (e) {
      this.activeFeatureConfiguration.activeTab = e
    },
    getLayoutClickHandler () {
      const config = JSON.parse(JSON.stringify(this.configuration))
      config.blueprint = {
        type: this.activeType,
        layout: this.activeLayout
      }
      this.$store.commit('setActiveConfiguration', config)
      this.$router.push({ name: 'GetLayout' })
    },
    createConfigurationBlueprint () {
      // load old config from store only if it matches
      if (this.activeConfiguration && this.activeConfiguration.blueprint && this.activeConfiguration.blueprint.type === this.activeType && this.activeConfiguration.blueprint.layout === this.activeLayout) {
        this.configuration = JSON.parse(JSON.stringify(this.activeConfiguration))
        if (this.configuration.blueprint) delete this.configuration.blueprint
        return
      }
      const blueprint = {}
      this.availableFeatures.forEach(feature => {
        const featureData = {}
        if (feature.positions) featureData.selectedPositions = [] // array of slugs
        if (feature.colors) featureData.selectedColors = [] // array of slugs
        if (feature.classes) {
          featureData.activeTab = feature.classes[0].slug

          if (feature.colors) {
            featureData.selectedColors = {}
            for (const i in feature.classes) {
              featureData.selectedColors[feature.classes[i].slug] = []
            }
          }
        }
        blueprint[feature.slug] = featureData
      })
      this.configuration = blueprint
    },
    toggleSummary () {
      this.showSummary = !this.showSummary
      this.dimmed = this.showSummary
    }
  }
}
</script>

<style lang="scss">
.configurator {
    &__inner {
        display: flex;
        flex-direction: column;
        height: 100vh;
        overflow: hidden;

        &.is-dimmed  {
            &:after {
                opacity: 1;
                pointer-events: auto;
            }
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(black, 0.3);
            z-index: 20;
            opacity: 0;
            pointer-events: none;
            transition: $hover-transition;
        }
    }
    &__main {
        margin-top: 30px !important;
        display: grid;
        grid-template-columns: 5fr 3fr;
        flex-grow: 1;
        overflow: hidden;
    }

    &__visual {
        position: relative;

        &__inner {
            width: 100%;
            height: 100%;
            opacity: 0;
            transform: translateY(50px);
            transition: all 700ms $ease-out-quad;

            .is-visible & {
                opacity: 1;
                transform: none;
            }
        }
    }

    &__side {
        position: relative;
        overflow: auto;
        display: flex;
        flex-direction: column;
        background: white;
        opacity: 0;
        transform: translateY(50px);
        transition: all 700ms $ease-out-quad;

        .is-visible & {
            opacity: 1;
            transform: none;
        }

        &__inner {
            padding: 46px 0;
            transition: all 700ms $ease-out-quad;

            &.is-hidden {
                opacity: 0;
                transform: translateY(20px);
                transition: none;
            }
        }

        &__title {
            margin-bottom: 8px;
        }

        &__text {
            &:not(:last-child) {
                margin-bottom: 40px;
            }
        }

        &__selector {
            &__label {
                margin-bottom: 12px;
            }
        }

        &__tabs {
            margin-bottom: 24px;
        }

        &__content {
            margin-top: 40px;

            &__item {
                &:not(:last-child) {
                    margin-bottom: 24px;
                }
            }
        }

        &__button {
            position: sticky;
            left: 0;
            bottom: 0;
            width: 100%;
            background: $color-white;
            padding: 30px 0;
            margin-top: auto;
            z-index: 10;
        }
    }
}
</style>
