<template>
    <div>
        <div class="help-button" @click="openClickHandler">
            <div class="help-button__icon color-primary">
                <icon>
                    <icon-help />
                </icon>
            </div>
            <div class="help-button__content">
                <div class="copy color-primary underlined">Need help?</div>
                <div class="copy">View infos</div>
            </div>
        </div>
    <el-dialog
        v-model="dialogVisible"
        title="Need Help building your perfect Cabin?"
        :append-to-body="true"
    >
        <div class="help-content">
            <ol class="help-content__list">
                <li v-for="(c, index) in helpContent" :key="index" class="help-content__list__item">
                    <div>
                        <div class="help-content__list__title h3 color-black">{{ c.title }}</div>
                        <div class="help-content__list__text copy">{{ c.text }}</div>
                    </div>
                </li>
            </ol>
            <div class="help-content__button-wrapper">
                <div class="help-content__button button button--primary" @click="closeClickHandler">Ok, all clear</div>
            </div>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import Icon from '@/components/Icon.vue'
import IconHelp from '@/components/icons/IconHelp.vue'
import { ElDialog } from 'element-plus'

export default {
  components: {
    Icon,
    IconHelp,
    ElDialog
  },
  data () {
    return {
      dialogVisible: false,
      helpContent: [
        {
          title: 'General',
          text: 'Our brand new FACC Cabin Builder allows you to build your own custom airplane interior based on the layout you want. Almost as easy as buying a new car.'
        },
        {
          title: 'Products',
          text: 'Browse through all 6 of our products to get more information and see how each one of them can look like in your aircrafts.'
        },
        {
          title: 'Summary',
          text: 'Want to see how you new Cabin looks like? You can open the summary screen from any step inside the cabin builder to get an overview of your selected products & positions.'
        },
        {
          title: 'Get your Layout',
          text: 'Click "Get Your Layout" inside the Summary screen to get a personalised Link that allows you to continue building your Cabin back at home.'
        },
        {
          title: 'Still troubles?',
          text: 'Still not sure how to use our new Tool? Or maybe you have a general question about one of our products? Just reach out to any of our staff and they are more than happy to help.'
        }
      ]
    }
  },
  methods: {
    openClickHandler () {
      this.dialogVisible = true
    },
    closeClickHandler () {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss">
.help-button {
    position: absolute;
    left: 32px;
    bottom: 32px;

    display: flex;
    align-items: center;

    &__icon {
        margin-right: 12px;
    }

    &__content {
        cursor: pointer;

        & > * {
            line-height: 1.3 !important;
        }
    }
}
.help-content {
    word-break: normal;
    &__list {
        list-style-type: none;
        counter-reset: list-counter 0;

        &__item {
            display: flex;

            &:not(:last-child) {
                margin-bottom: 32px;
            }

            &:before {
                counter-increment: list-counter;
                content: counters(list-counter, ".");
                border-radius: 100%;
                color: $color-primary;
                border: 1px solid $color-primary;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                margin-right: 8px;
                margin-top: 2px;
            }
        }

        &__title {
            margin-bottom: 8px;
        }
    }

    &__button-wrapper {
        margin-top: 40px;
        text-align: center;
    }

    &__button {
        width: 100%;
        max-width: 446px;
    }
}
</style>
