<template>
    <div class="summary-sidebar" v-click-outside="outsideClickHandler">
        <div class="summary-sidebar__inner">
            <div class="summary-sidebar__title h2">Summary</div>
            <div class="summary-sidebar__close" @click="closeClickHandler">
                <icon :size="32">
                    <icon-close />
                </icon>
            </div>
            <div class="summary-sidebar__feature" v-for="feature in availableFeatures" :key="feature.slug">
                <div class="summary-sidebar__feature__title h3 color-primary">{{ feature.label }}</div>
                <div class="summary-sidebar__feature__field" v-if="feature.positions">
                    <div class="copy color-black">Position</div>
                    <div class="summary-sidebar__feature__field__right" @click="goToClickHandler(feature.slug)">
                        <div class="copy color-primary underlined" v-if="activeConfiguration[feature.slug].selectedPositions.length === 0">Select a position</div>
                        <div class="copy" v-else>{{ selectionLabels(activeConfiguration[feature.slug].selectedPositions, feature.positions) }}</div>
                        <icon>
                            <icon-chevron-right />
                        </icon>
                    </div>
                </div>
                <template v-if="feature.colors && feature.classes">
                    <div class="summary-sidebar__feature__field" v-for="classItem in feature.classes" :key="classItem.slug">
                        <div class="copy color-black">{{ classItem.label }} Class Color</div>
                        <div class="summary-sidebar__feature__field__right" @click="goToClickHandler(feature.slug, classItem.slug)">
                            <div class="copy color-primary underlined" v-if="colorSelectionSlugs(activeConfiguration[feature.slug].selectedColors[classItem.slug]).length === 0">Select a color</div>
                            <div class="copy" v-else>{{ selectionLabels(activeConfiguration[feature.slug].selectedColors[classItem.slug], feature.availableColors) }}</div>
                            <icon>
                                <icon-chevron-right />
                            </icon>
                        </div>
                    </div>
                </template>
            </div>
            <div class="summary-sidebar__buttons">
                <div class="button button--fullwidth" @click="getLayoutClickHandler">Get layout</div>
                <div class="button button--fullwidth button--ghost" @click="resetClickHandler">Reset all</div>
            </div>
        </div>
    </div>
</template>

<script>
import { clickOutside } from '@/directives/click-outside'
import Icon from '@/components/Icon.vue'
import IconChevronRight from '@/components/icons/IconChevronRight.vue'
import IconClose from '@/components/icons/IconClose.vue'

export default {
  name: 'SummarySidebar',
  directives: {
    clickOutside
  },
  components: {
    Icon,
    IconChevronRight,
    IconClose
  },
  props: {
    activeConfiguration: {
      type: Object,
      default: () => {}
    },
    availableFeatures: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      visible: false
    }
  },
  computed: {
    selectionLabels () {
      return (slugs, fullData) => {
        return slugs.map(slug => fullData.find(data => data.slug === slug).label).join(', ')
      }
    },
    colorSelectionSlugs () {
      return (colors) => {
        let selectedColors = []
        for (const i in colors) {
          selectedColors = [...selectedColors, ...colors[i]]
        }
        return selectedColors
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.visible = true
    }, 300)
  },
  methods: {
    closeClickHandler () {
      this.$emit('toggle')
    },
    goToClickHandler (slug, tab = false) {
      this.$emit('goToFeature', { slug, tab })
    },
    resetClickHandler () {
      this.$emit('resetClick')
      this.$emit('toggle')
    },
    getLayoutClickHandler () {
      this.$emit('getLayoutClick')
    },
    outsideClickHandler () {
      if (this.visible) {
        this.$emit('toggle')
      }
    }
  }
}
</script>

<style lang="scss">
.summary-sidebar {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 510px;
    max-width: 100%;
    z-index: 200;
    background: $color-white;
    box-shadow: $shadow-l;
    padding: 40px 32px 30px;
    overflow: auto;

    &__inner {
        position: relative;
    }

    &__title {
        margin-bottom: 40px;
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
    }

    &__feature {
        border-bottom: 2px solid $color-grey-medium;
        padding-bottom: 24px;
        margin-bottom: 24px;

        &__title {
            margin-bottom: 24px;
        }

        &__field {
            display: flex;
            justify-content: space-between;

            &:not(:last-child) {
                margin-bottom: 4px;
            }

            &__right {
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    &__buttons {
        margin-top: 50px;
        display: grid;
        gap: 16px;
    }
}
</style>
