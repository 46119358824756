<template>
    <div class="type-switch">
        <div class="type-switch__option uptitle color-primary" :class="{'is-active' : option.slug === activeOption}" v-for="(option, index) in options" :key="index" @click="optionClickHandler(option.slug)">
            {{ option.label }}
        </div>
    </div>
</template>

<script>
export default {
  name: 'TypeSwitch',
  props: {
    options: {
      type: Array,
      default: () => []
    },
    activeOption: {
      type: [String, Boolean],
      default: false
    }
  },
  methods: {
    optionClickHandler (slug) {
      this.$emit('optionClick', slug)
    }
  }
}
</script>

<style lang="scss">
    .type-switch {
        $block: &;
        display: inline-flex;
        padding: 8px;
        gap: 16px;
        background: $color-white;
        border-radius: $border-radius-m;
        box-shadow: $shadow-l;

        &--tabs {
            display: flex;
            box-shadow: none;
            background: $color-grey-light;

            #{$block} {
                &__option {
                    flex-grow: 1;
                    color: $color-black;
                    padding: 12px 16px;
                    text-align: center;
                    text-transform: none;
                    letter-spacing: normal;
                    font-size: 20px;
                    line-height: 1;
                }
            }
        }

        &__option {
            padding: 16px;
            border-radius: $border-radius-s;
            transition: $hover-transition;
            cursor: pointer;

            &.is-active {
                background: $color-primary;
                color: $color-white;
            }
        }
    }
</style>
