<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="873.1px" height="1118px" viewBox="0 0 873.1 1118" enable-background="new 0 0 873.1 1118" xml:space="preserve">
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75"  d="M550.3,545.8 c0.2,11.7,1.3,12.7,3.2,17.5c0.1,0.2,0.3,0.3,0.5,0.3s0.4-0.1,0.5-0.3c1.9-4.8,3.2-5.8,3.4-17.5"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75"  d="M637.2,555.4 c0.2,14.3,1.6,16.6,3.9,22.4c0.1,0.3,0.4,0.4,0.7,0.4s0.5-0.2,0.7-0.4c2.3-5.5,3.7-7,4.1-19.4"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75"  d="M573.6,545.9 c0.1,6.5,0.9,7.1,2.2,9.7c0.1,0.1,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2c1.3-2.7,2.2-3.2,2.3-9.7H573.6z"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75"  d="M322.8,545.8 c-0.2,11.7-1.3,12.7-3.2,17.5c-0.1,0.2-0.3,0.4-0.5,0.4s-0.4-0.1-0.5-0.4c-1.9-4.8-3.2-5.8-3.4-17.5"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75"  d="M235.9,555.4 c-0.2,14.3-1.6,16.6-3.9,22.4c-0.1,0.3-0.4,0.4-0.7,0.4s-0.5-0.2-0.7-0.4c-2.2-5.5-3.7-7-4.1-19.4"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75"  d="M299.5,545.9 c-0.1,6.5-0.9,7.1-2.2,9.7c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.3-0.1-0.4-0.2c-1.3-2.7-2.2-3.2-2.3-9.7H299.5z"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75"  d="M487.3,390.4 c0,3.8,2.1,5.5,5.6,7.2l175.9,88l193.6,97c0,0,9.6,4.5,9.6,18.6c-0.1,14.1,0,34.2,0,34.2h-3l-0.2-14l-127.5-36.1l-139.1-39.4H487.3" />
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75"  d="M595.6,449 c8-5.2,9-30.7,9.5-46.8c0.2-8.7-0.2-17.4-1.3-26.1c-0.9-6.3-2-12.8-3.8-16.2h-42c0,0-6,8.7-5.8,30.4c0.1,12.9,1.2,25.8,3.3,38.5"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75"  d="M385.8,390.4 c0,3.8-2.1,5.5-5.6,7.2l-175.9,88l-193.6,97c0,0-9.6,4.5-9.6,18.6s0,34.2,0,34.2h3l0.2-14l127.5-36.1L271,545.9h114.8"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75"  d="M277.5,449 c-8-5.2-9-30.7-9.5-46.8c-0.2-8.7,0.2-17.4,1.3-26.1c0.8-6.3,2-12.8,3.8-16.2h42c0,0,6,8.7,5.8,30.4c-0.1,12.9-1.2,25.8-3.3,38.5"/>
        <path class="plane-body" opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75"  d="M428.9,3.8 c-38.8,31-43.8,124.5-43.8,124.5c0,99.4,0,376.6,0,376.6s-0.7,343.7,0,343c1.3,94.7,51.3,226.1,51.3,226.1h-0.2 c0,0,49.8-131.5,51.1-226.1c0.7,0.7,0-343,0-343s0-277.2,0-376.6c0,0-3.7-93.4-42.4-124.4C440.2,0.1,433.6,0.1,428.9,3.8z"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75"  d="M405.6,973.2l-175.7,121.1v23 l202.9-52.4h7.1l202.9,52.4v-23L467,973.2"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M768.5,593v-6.8l-166.8-52.9 H487.3v12.5h114.8L768.5,593z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M573,437.7v-55.4 c0-1.7,0.7-3.2,1.9-4.4c1.2-1.1,2.8-1.8,4.4-1.7c3.4-0.1,6.3,2.7,6.4,6.1V444"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M503.3,402.8l-7,11.3 l167.2,83.8l183,90.9l5.8-11.2l-183.6-92L503.3,402.8z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M763.5,584.6l5-16.2L651,530.1 l-39.3-12.8l-6.9,17.1l40.7,12.9L763.5,584.6z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M487.3,533.4h111.3v-16.1 h-48.3v6.1h-63V533.4z"/>
        <path opacity="0.4" fill="none" stroke="#33546C" stroke-width="0.5"  d="M436.4,1082.4 c-4.2-23.9-7-56.3-8.4-112.5c-1.6-64.6,3.6-99.2,8.4-116.3h-0.2c4.8,17.1,10,51.7,8.4,116.3c-1.4,56.3-4.2,88.6-8.4,112.5"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.75"  d="M555.4,365.9 c0.6-2.1,1.5-4,2.6-5.9h42c0.8,1.8,1.5,3.7,1.9,5.7L555.4,365.9L555.4,365.9z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M104.7,593v-6.8l166.8-52.9 h114.4v12.5H271L104.7,593z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M300,437.7v-55.4 c-0.1-3.4-2.9-6.2-6.4-6.1c-1.7,0-3.3,0.6-4.4,1.7s-1.9,2.7-1.9,4.4V444"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M369.8,402.8l7,11.3 l-167.2,83.8l-183,90.9l-5.8-11.2l183.6-92L369.8,402.8z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M109.7,584.6l-5-16.2 l117.5-38.3l39.3-12.8l6.9,17.1l-40.7,12.9L109.7,584.6z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M385.8,533.4H274.5v-16.1h48.3 v6.1h62.9v10H385.8z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.75"  d="M317.8,365.9 c-0.6-2.1-1.5-4-2.6-5.9h-42.1c-0.9,1.8-1.5,3.8-2,5.7L317.8,365.9L317.8,365.9z"/>
        <path opacity="0.4" fill="none" stroke="#33546C" stroke-width="0.75"  d="M461.5,49l-5.3,10.5l7.5,10.2 h9.3L461.5,49z"/>
        <path opacity="0.4" fill="none" stroke="#33546C" stroke-width="0.75"  d="M474.3,73h-8.9l4.5,12.5h7 L474.3,73z"/>
        <path opacity="0.4" fill="none" stroke="#33546C" stroke-width="0.75"  d="M411.1,49l5.3,10.5l-7.5,10.2 h-9.3L411.1,49z"/>
        <path opacity="0.4" fill="none" stroke="#33546C" stroke-width="0.75"  d="M398.3,73h8.9l-4.4,12.5h-7 L398.3,73z"/>
        <g opacity="0.4">
            <path opacity="0.4" fill="none" stroke="#33546C" stroke-width="0.75"  d="M453.3,56.3l4.8-11.2 l-20.5-13.2v14.7L453.3,56.3z"/>
            <path opacity="0.4" fill="none" stroke="#33546C" stroke-width="0.75"  d="M434.8,32.1l-20.3,13.1 l4.8,11.2l15.5-9.6V32.1z"/>
        </g>
        <g opacity="0.2">
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M564.8,433.5l-6.2,11.7"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M608,455.2v14.6"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M668.8,485.6l-5.3,12.3"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M730.2,516.4l-5.8,11.7"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M791.7,547.2l-5.8,11.5"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M730.2,555.9l-5.8,16.3"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M690.7,543.1l-5.8,16.7"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M650.9,530.1l-5.5,17.1"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M550.3,523.4v10"/>
        </g>
        <g opacity="0.2">
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M308.3,433.5l6.2,11.7"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M265.2,455.2v14.6"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M204.3,485.6l5.3,12.3"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M142.9,516.4l5.8,11.7"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M81.4,547.2l5.8,11.5"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M142.9,555.9l5.8,16.3"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M182.4,543.1l5.8,16.7"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M222.2,530.1l5.5,17.1"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5"  d="M322.8,523.4v10"/>
        </g>
        <path fill="#FFFFFF" d="M436.7,1035.9c0,0-2.8,2.8-3.6,23.5c-0.5,13.4,2.2,25.5,3.6,28.3h-0.2c1.4-2.8,4.1-14.9,3.6-28.3 c-0.8-20.7-3.6-23.5-3.6-23.5"/>
        <path fill="none" stroke="#33546C" stroke-width="0.75" d="M436.7,1035.9c0,0-2.8,2.8-3.6,23.5c-0.5,13.4,2.2,25.5,3.6,28.3h-0.2 c1.4-2.8,4.1-14.9,3.6-28.3c-0.8-20.7-3.6-23.5-3.6-23.5"/>
        <g>
            <path opacity="0.125" fill="#737373"  d="M430.1,341.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,341.9,430.1,341.7,430.1,341.4z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,341.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,341.9,415.6,341.7,415.6,341.4z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,341.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,341.9,401.1,341.7,401.1,341.4z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,341.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,341.9,484.7,341.7,484.7,341.4z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,341.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,341.9,470.2,341.7,470.2,341.4z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,341.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,341.9,455.7,341.7,455.7,341.4z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,310.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,311.2,430.1,311,430.1,310.7z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,326v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V326c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,326.5,430.1,326.3,430.1,326z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,310.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,311.2,415.6,311,415.6,310.7z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,326v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V326c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,326.5,415.6,326.3,415.6,326z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,310.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,311.2,401.1,311,401.1,310.7z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,326v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V326c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,326.5,401.1,326.3,401.1,326z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,295.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,295.8,430.1,295.6,430.1,295.3z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,295.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,295.8,415.6,295.6,415.6,295.3z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,295.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,295.8,401.1,295.6,401.1,295.3z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,280v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V280c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,280.5,430.1,280.3,430.1,280z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,280v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V280c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,280.5,415.6,280.3,415.6,280z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,280v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V280c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,280.5,401.1,280.3,401.1,280z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,264.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,265.1,430.1,264.9,430.1,264.6z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,264.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,265.1,415.6,264.9,415.6,264.6z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,264.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,265.1,401.1,264.9,401.1,264.6z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,310.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,311.2,484.7,311,484.7,310.7z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,326v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V326c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,326.5,484.7,326.3,484.7,326z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,310.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,311.2,470.2,311,470.2,310.7z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,326v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V326c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,326.5,470.2,326.3,470.2,326z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,310.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,311.2,455.7,311,455.7,310.7z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,326v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V326c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,326.5,455.7,326.3,455.7,326z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,295.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,295.8,484.7,295.6,484.7,295.3z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,295.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,295.8,470.2,295.6,470.2,295.3z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,295.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,295.8,455.7,295.6,455.7,295.3z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,280v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V280c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,280.5,484.7,280.3,484.7,280z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,280v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V280c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,280.5,470.2,280.3,470.2,280z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,280v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V280c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,280.5,455.7,280.3,455.7,280z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,264.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,265.1,484.7,264.9,484.7,264.6z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,264.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,265.1,470.2,264.9,470.2,264.6z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,264.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,265.1,455.7,264.9,455.7,264.6z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,249.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,249.8,430.1,249.6,430.1,249.3z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,249.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,249.8,415.6,249.6,415.6,249.3z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,249.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,249.8,401.1,249.6,401.1,249.3z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,249.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,249.8,484.7,249.6,484.7,249.3z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,249.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,249.8,470.2,249.6,470.2,249.3z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,249.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,249.8,455.7,249.6,455.7,249.3z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,418.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,418.6,430.1,418.4,430.1,418.1z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,433.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,434,430.1,433.8,430.1,433.5z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,418.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,418.6,415.6,418.4,415.6,418.1z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,433.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,434,415.6,433.8,415.6,433.5z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,418.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,418.6,401.1,418.4,401.1,418.1z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,433.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,434,401.1,433.8,401.1,433.5z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,402.8V391c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,403.3,430.1,403.1,430.1,402.8z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,402.8V391c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,403.3,415.6,403.1,415.6,402.8z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,402.8V391c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,403.3,401.1,403.1,401.1,402.8z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,387.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,387.9,430.1,387.7,430.1,387.4z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,387.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,387.9,415.6,387.7,415.6,387.4z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,387.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,387.9,401.1,387.7,401.1,387.4z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,372.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,372.6,430.1,372.4,430.1,372.1z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,372.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,372.6,415.6,372.4,415.6,372.1z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,372.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,372.6,401.1,372.4,401.1,372.1z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,418.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,418.6,484.7,418.4,484.7,418.1z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,433.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,434,484.7,433.8,484.7,433.5z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,418.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,418.6,470.2,418.4,470.2,418.1z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,433.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,434,470.2,433.8,470.2,433.5z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,418.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,418.6,455.7,418.4,455.7,418.1z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,433.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,434,455.7,433.8,455.7,433.5z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,402.8V391c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,403.3,484.7,403.1,484.7,402.8z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,402.8V391c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,403.3,470.2,403.1,470.2,402.8z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,402.8V391c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,403.3,455.7,403.1,455.7,402.8z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,387.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,387.9,484.7,387.7,484.7,387.4z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,387.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,387.9,470.2,387.7,470.2,387.4z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,387.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,387.9,455.7,387.7,455.7,387.4z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,372.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,372.6,484.7,372.4,484.7,372.1z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,372.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,372.6,470.2,372.4,470.2,372.1z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,372.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,372.6,455.7,372.4,455.7,372.1z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,356.7V345c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,357.2,430.1,357,430.1,356.7z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,356.7V345c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,357.2,415.6,357,415.6,356.7z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,356.7V345c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,357.2,401.1,357,401.1,356.7z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,356.7V345c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,357.2,484.7,357,484.7,356.7z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,356.7V345c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,357.2,470.2,357,470.2,356.7z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,356.7V345c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,357.2,455.7,357,455.7,356.7z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,453.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,453.6,430.1,453.4,430.1,453.1z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,453.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,453.6,415.6,453.4,415.6,453.1z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,453.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,453.6,401.1,453.4,401.1,453.1z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,453.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,453.6,484.7,453.4,484.7,453.1z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,453.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,453.6,470.2,453.4,470.2,453.1z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,453.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,453.6,455.7,453.4,455.7,453.1z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,639.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,640.4,430.1,640.2,430.1,639.9z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,639.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,640.4,415.6,640.2,415.6,639.9z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,639.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,640.4,401.1,640.2,401.1,639.9z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,639.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,640.4,484.7,640.2,484.7,639.9z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,639.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,640.4,470.2,640.2,470.2,639.9z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,639.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,640.4,455.7,640.2,455.7,639.9z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,518.8V507c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,519.3,430.1,519.1,430.1,518.8z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,534.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,534.6,430.1,534.4,430.1,534.1z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,518.8V507c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,519.3,415.6,519.1,415.6,518.8z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,534.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,534.6,415.6,534.4,415.6,534.1z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,518.8V507c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,519.3,401.1,519.1,401.1,518.8z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,534.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,534.6,401.1,534.4,401.1,534.1z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,503.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,503.9,430.1,503.7,430.1,503.4z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,503.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,503.9,415.6,503.7,415.6,503.4z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,503.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,503.9,401.1,503.7,401.1,503.4z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,488.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,488.6,430.1,488.4,430.1,488.1z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,488.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,488.6,415.6,488.4,415.6,488.1z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,488.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,488.6,401.1,488.4,401.1,488.1z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,472.7V461c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,473.2,430.1,473,430.1,472.7z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,472.7V461c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,473.2,415.6,473,415.6,472.7z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,472.7V461c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,473.2,401.1,473,401.1,472.7z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,518.8V507c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,519.3,484.7,519.1,484.7,518.8z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,534.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,534.6,484.7,534.4,484.7,534.1z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,518.8V507c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,519.3,470.2,519.1,470.2,518.8z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,534.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,534.6,470.2,534.4,470.2,534.1z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,518.8V507c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,519.3,455.7,519.1,455.7,518.8z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,534.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,534.6,455.7,534.4,455.7,534.1z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,503.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,503.9,484.7,503.7,484.7,503.4z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,503.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,503.9,470.2,503.7,470.2,503.4z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,503.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,503.9,455.7,503.7,455.7,503.4z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,488.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,488.6,484.7,488.4,484.7,488.1z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,488.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,488.6,470.2,488.4,470.2,488.1z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,488.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,488.6,455.7,488.4,455.7,488.1z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,472.7V461c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,473.2,484.7,473,484.7,472.7z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,472.7V461c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,473.2,470.2,473,470.2,472.7z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,472.7V461c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,473.2,455.7,473,455.7,472.7z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,610.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,611.4,430.1,611.2,430.1,610.9z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,610.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,611.4,415.6,611.2,415.6,610.9z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,595.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,596,430.1,595.8,430.1,595.5z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,595.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,596,415.6,595.8,415.6,595.5z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,595.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,596,401.1,595.8,401.1,595.5z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,580.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,580.7,430.1,580.5,430.1,580.2z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,580.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,580.7,415.6,580.5,415.6,580.2z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,580.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,580.7,401.1,580.5,401.1,580.2z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,564.8V553c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,565.3,430.1,565.1,430.1,564.8z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,564.8V553c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,565.3,415.6,565.1,415.6,564.8z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,564.8V553c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,565.3,401.1,565.1,401.1,564.8z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,610.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,611.4,470.2,611.2,470.2,610.9z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,610.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,611.4,455.7,611.2,455.7,610.9z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,595.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,596,484.7,595.8,484.7,595.5z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,595.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,596,470.2,595.8,470.2,595.5z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,595.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,596,455.7,595.8,455.7,595.5z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,580.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,580.7,484.7,580.5,484.7,580.2z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,580.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,580.7,470.2,580.5,470.2,580.2z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,580.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,580.7,455.7,580.5,455.7,580.2z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,564.8V553c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,565.3,484.7,565.1,484.7,564.8z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,564.8V553c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,565.3,470.2,565.1,470.2,564.8z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,564.8V553c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,565.3,455.7,565.1,455.7,564.8z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,549.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,550,430.1,549.8,430.1,549.5z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,549.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,550,415.6,549.8,415.6,549.5z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,549.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,550,401.1,549.8,401.1,549.5z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,549.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,550,484.7,549.8,484.7,549.5z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,549.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,550,470.2,549.8,470.2,549.5z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,549.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,550,455.7,549.8,455.7,549.5z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,716.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,717.1,430.1,716.9,430.1,716.6z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,732v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V732c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,732.5,430.1,732.3,430.1,732z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,716.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,717.1,415.6,716.9,415.6,716.6z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,732v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V732c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,732.5,415.6,732.3,415.6,732z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,716.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,717.1,401.1,716.9,401.1,716.6z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,732v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V732c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,732.5,401.1,732.3,401.1,732z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,701.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,701.8,430.1,701.6,430.1,701.3z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,701.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,701.8,415.6,701.6,415.6,701.3z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,701.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,701.8,401.1,701.6,401.1,701.3z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,762.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,763.2,430.1,763,430.1,762.7z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,778v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V778c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,778.6,430.1,778.3,430.1,778z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,793.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,793.9,430.1,793.7,430.1,793.4z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,808.7V797c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,809.3,430.1,809,430.1,808.7z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,762.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,763.2,415.6,763,415.6,762.7z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,778v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V778c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,778.6,415.6,778.3,415.6,778z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,793.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,793.9,415.6,793.7,415.6,793.4z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,808.7V797c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,809.3,415.6,809,415.6,808.7z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,762.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,763.2,401.1,763,401.1,762.7z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,778v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V778c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,778.6,401.1,778.3,401.1,778z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,793.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,793.9,401.1,793.7,401.1,793.4z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,808.7V797c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,809.3,401.1,809,401.1,808.7z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,747.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,747.9,430.1,747.6,430.1,747.3z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,747.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,747.9,415.6,747.6,415.6,747.3z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,747.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,747.9,401.1,747.6,401.1,747.3z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,685.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,686.4,430.1,686.2,430.1,685.9z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,685.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,686.4,415.6,686.2,415.6,685.9z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,685.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,686.4,401.1,686.2,401.1,685.9z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,670.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,671.1,430.1,670.9,430.1,670.6z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,670.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,671.1,415.6,670.9,415.6,670.6z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,670.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,671.1,401.1,670.9,401.1,670.6z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,716.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,717.1,484.7,716.9,484.7,716.6z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,732v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V732c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,732.5,484.7,732.3,484.7,732z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,716.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,717.1,470.2,716.9,470.2,716.6z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,732v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V732c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,732.5,470.2,732.3,470.2,732z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,716.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,717.1,455.7,716.9,455.7,716.6z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,732v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V732c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,732.5,455.7,732.3,455.7,732z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,747.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,747.9,484.7,747.6,484.7,747.3z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,762.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,763.2,484.7,763,484.7,762.7z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,747.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,747.9,470.2,747.6,470.2,747.3z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,762.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,763.2,470.2,763,470.2,762.7z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,747.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,747.9,455.7,747.6,455.7,747.3z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,762.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,763.2,455.7,763,455.7,762.7z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,778v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V778c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,778.6,484.7,778.3,484.7,778z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,793.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,793.9,484.7,793.7,484.7,793.4z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,808.7V797c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,809.3,484.7,809,484.7,808.7z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,778v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V778c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,778.6,470.2,778.3,470.2,778z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,793.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,793.9,470.2,793.7,470.2,793.4z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,808.7V797c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,809.3,470.2,809,470.2,808.7z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,778v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V778c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,778.6,455.7,778.3,455.7,778z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,793.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,793.9,455.7,793.7,455.7,793.4z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,808.7V797c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,809.3,455.7,809,455.7,808.7z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,701.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,701.8,484.7,701.6,484.7,701.3z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,701.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,701.8,470.2,701.6,470.2,701.3z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,701.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,701.8,455.7,701.6,455.7,701.3z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,685.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,686.4,484.7,686.2,484.7,685.9z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,685.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,686.4,470.2,686.2,470.2,685.9z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,685.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,686.4,455.7,686.2,455.7,685.9z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,670.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,671.1,484.7,670.9,484.7,670.6z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,670.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,671.1,470.2,670.9,470.2,670.6z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,670.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,671.1,455.7,670.9,455.7,670.6z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,655.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,655.7,430.1,655.5,430.1,655.2z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,655.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,655.7,415.6,655.5,415.6,655.2z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,655.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,655.7,401.1,655.5,401.1,655.2z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,655.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,655.7,484.7,655.5,484.7,655.2z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,655.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,655.7,470.2,655.5,470.2,655.2z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,655.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,655.7,455.7,655.5,455.7,655.2z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,233.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,234.4,430.1,234.2,430.1,233.9z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,233.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,234.4,415.6,234.2,415.6,233.9z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,233.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,234.4,401.1,234.2,401.1,233.9z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,218.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,219.1,430.1,218.9,430.1,218.6z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,218.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,219.1,415.6,218.9,415.6,218.6z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,218.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,219.1,401.1,218.9,401.1,218.6z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,203.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,203.7,430.1,203.5,430.1,203.2z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,203.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,203.7,415.6,203.5,415.6,203.2z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,203.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,203.7,401.1,203.5,401.1,203.2z"/>
            <path opacity="0.125" fill="#737373"  d="M430.1,187.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C429.9,188.4,430.1,188.2,430.1,187.9z"/>
            <path opacity="0.125" fill="#737373"  d="M415.6,187.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C415.4,188.4,415.6,188.2,415.6,187.9z"/>
            <path opacity="0.125" fill="#737373"  d="M401.1,187.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C400.9,188.4,401.1,188.2,401.1,187.9z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,233.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,234.4,484.7,234.2,484.7,233.9z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,233.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,234.4,470.2,234.2,470.2,233.9z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,233.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,234.4,455.7,234.2,455.7,233.9z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,218.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,219.1,484.7,218.9,484.7,218.6z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,218.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,219.1,470.2,218.9,470.2,218.6z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,218.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,219.1,455.7,218.9,455.7,218.6z"/>
            <path opacity="0.125" fill="#737373"  d="M484.7,203.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C484.4,203.7,484.7,203.5,484.7,203.2z"/>
            <path opacity="0.125" fill="#737373"  d="M470.2,203.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C469.9,203.7,470.2,203.5,470.2,203.2z"/>
            <path opacity="0.125" fill="#737373"  d="M455.7,203.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C455.4,203.7,455.7,203.5,455.7,203.2z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('lav4all', 'aft-section-left')">
            <path class="editable-content__background" fill="#C4C4C4" d="M417.4,833.7v18.2c0,1.1-0.9,2-2,2h-25c-1.1,0-2-0.9-2-2v-18.2c0-1.1,0.9-2,2-2h25 C416.5,831.7,417.4,832.6,417.4,833.7z"/>
            <path class="editable-content__icon" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M398.2,835.9c0.7,0,1.3,0.4,1.5,1c0.3,0.6,0.1,1.3-0.3,1.8 c-0.5,0.5-1.2,0.6-1.8,0.4c-0.6-0.2-1-0.8-1-1.5C396.6,836.7,397.3,835.9,398.2,835.9z M406,835.9c0.9,0,1.6,0.7,1.6,1.6 c0,0.9-0.7,1.6-1.6,1.6s-1.6-0.7-1.6-1.6C404.3,836.7,405.1,835.9,406,835.9z M407.3,847.3c-0.1,0.7-0.2,1.5-0.3,2.2h-2.1 c-0.1-0.7-0.2-1.5-0.3-2.2h-2.4l1.8-4.3c-0.2-0.9-0.4-1.7-0.5-2.6h4.9c-0.2,0.9-0.4,1.7-0.5,2.6l1.8,4.3H407.3z M400.3,845l-0.6,4.6 h-2.8l-0.6-4.6h-1c0.2-1.5,0.4-3.1,0.6-4.6h4.8c0.2,1.5,0.4,3.1,0.6,4.6H400.3z"/>
        </g>
        <path fill="#C4C4C4" d="M430.2,124.1v18.2c0,1.1-0.9,2-2,2h-37.8c-1.1,0-2-0.9-2-2v-18.2c0-1.1,0.9-2,2-2h37.8 C429.3,122.1,430.2,123,430.2,124.1z"/>
        <path fill="#FFFFFF" d="M401.1,136.7h15.5c0,0.9-0.7,1.6-1.6,1.6h-12.4C401.8,138.3,401.1,137.6,401.1,136.7L401.1,136.7z"/>
        <path fill="#FFFFFF" d="M404.1,128.3h9.2v6.1c0,0.8-0.7,1.5-1.5,1.5h-6c-0.4,0-0.8-0.2-1-0.4c-0.3-0.3-0.4-0.7-0.4-1V134 c-0.8,0-1.5-0.2-2.1-0.8c-0.6-0.5-0.9-1.2-0.9-2C401.4,129.6,402.6,128.3,404.1,128.3z M404.4,132.7v-3.2h-0.3 c-0.9,0-1.6,0.7-1.6,1.6c0,0.5,0.2,1,0.6,1.3C403.4,132.6,403.9,132.8,404.4,132.7z"/>
        <path fill="#C4C4C4" d="M484.8,833.7v18.2c0,1.1-0.9,2-2,2h-25c-1.1,0-2-0.9-2-2v-18.2c0-1.1,0.9-2,2-2h25 C483.9,831.7,484.8,832.6,484.8,833.7z"/>
        <path fill="#FFFFFF" d="M462.5,846.3H478c0,0.9-0.7,1.6-1.6,1.6H464C463.1,847.8,462.4,847.1,462.5,846.3L462.5,846.3z"/>
        <path fill="#FFFFFF" d="M465.4,837.8h9.2v6.1c0,0.8-0.7,1.5-1.5,1.5h-6c-0.4,0-0.8-0.2-1-0.4c-0.3-0.3-0.4-0.7-0.4-1v-0.5 c-0.8,0-1.5-0.2-2.1-0.8c-0.6-0.5-0.9-1.2-0.9-2C462.8,839.1,463.9,837.9,465.4,837.8z M465.7,842.2V839h-0.3 c-0.9,0-1.6,0.7-1.6,1.6c0,0.5,0.2,1,0.6,1.3S465.2,842.3,465.7,842.2z"/>
        <g class="editable-content pulse-item" :class="contentClasses('partition', 'economy')">
            <path class="editable-content__background" fill="#C4C4C4" d="M430.2,166.8v-2c0-1.1-0.9-2-2-2h-37.8c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h37.8 C429.3,168.8,430.2,167.9,430.2,166.8z"/>
            <path class="editable-content__background" fill="#C4C4C4" d="M484.8,166.8v-2c0-1.1-0.9-2-2-2H445c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h37.8 C483.9,168.8,484.8,167.9,484.8,166.8z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('lav4all', 'front-section')">
            <path class="editable-content__background" fill="#C4C4C4" d="M484.8,124.1v18.2c0,1.1-0.9,2-2,2H445c-1.1,0-2-0.9-2-2v-18.2c0-1.1,0.9-2,2-2h37.8 C483.9,122.1,484.8,123,484.8,124.1z"/>
            <path class="editable-content__icon" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M459.6,126.4c0.7,0,1.3,0.4,1.5,1c0.3,0.6,0.1,1.3-0.4,1.8 s-1.2,0.6-1.8,0.4c-0.6-0.2-1-0.8-1-1.5C458,127.1,458.7,126.4,459.6,126.4z M467.4,126.4c0.9,0,1.6,0.7,1.6,1.6 c0,0.9-0.7,1.6-1.6,1.6s-1.6-0.7-1.6-1.6C465.7,127.1,466.5,126.4,467.4,126.4z M468.7,137.7c-0.1,0.7-0.2,1.5-0.3,2.2h-2.1 c-0.1-0.7-0.2-1.5-0.3-2.2h-2.4l1.8-4.3c-0.2-0.9-0.4-1.7-0.5-2.6h4.9c-0.2,0.9-0.4,1.7-0.5,2.6l1.8,4.3H468.7z M461.7,135.4 L461,140h-2.8l-0.6-4.6h-1c0.2-1.5,0.4-3.1,0.6-4.6h4.8c0.2,1.5,0.4,3.1,0.6,4.6H461.7z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('lav4all', 'aft-section-right')">
            <path class="editable-content__background" fill="#C4C4C4" d="M451.1,833.7v18.2c0,1.1-0.9,2-2,2h-25c-1.1,0-2-0.9-2-2v-18.2c0-1.1,0.9-2,2-2h25 C450.2,831.7,451.1,832.6,451.1,833.7z"/>
            <path class="editable-content__icon" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M431.9,835.9c0.7,0,1.3,0.4,1.5,1c0.3,0.6,0.1,1.3-0.3,1.8 c-0.5,0.5-1.2,0.6-1.8,0.4s-1-0.8-1-1.5C430.3,836.7,431,835.9,431.9,835.9z M439.7,835.9c0.9,0,1.6,0.7,1.6,1.6 c0,0.9-0.7,1.6-1.6,1.6s-1.6-0.7-1.6-1.6C438,836.7,438.8,835.9,439.7,835.9z M441,847.3c-0.1,0.7-0.2,1.5-0.3,2.2h-2.1 c-0.1-0.7-0.2-1.5-0.3-2.2h-2.4l1.8-4.3c-0.2-0.9-0.4-1.7-0.5-2.6h4.9c-0.2,0.9-0.4,1.7-0.5,2.6l1.8,4.3H441z M433.9,845l-0.6,4.6 h-2.8l-0.6-4.6h-1c0.2-1.5,0.4-3.1,0.6-4.6h4.8c0.2,1.5,0.4,3.1,0.6,4.6H433.9z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('moveableDivider', 'economy')">
            <path class="editable-content__background" fill="transparent" d="M430.1,235.7L430.1,235.7c0-0.7-0.6-1.3-1.3-1.3h-39.2c-0.7,0-1.3,0.6-1.3,1.3l0,0c0,0.7,0.6,1.3,1.3,1.3 h39.2C429.5,237,430.1,236.4,430.1,235.7z"/>
            <path class="editable-content__background" fill="transparent" d="M484.8,235.7L484.8,235.7c0-0.7-0.6-1.3-1.3-1.3h-39.3c-0.7,0-1.3,0.6-1.3,1.3l0,0c0,0.7,0.6,1.3,1.3,1.3 h39.2C484.1,237,484.8,236.4,484.8,235.7z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('centerCeilingStowage', 'front-section')">
            <path class="editable-content__background" fill="transparent" d="M433.1,240.1h6.8c0,0,0.1-0.1,0.1-0.2v-41.7c0-0.1,0-0.2-0.1-0.2h-6.8c0,0-0.1,0.1-0.1,0.2v41.7  C433,240,433.1,240.1,433.1,240.1z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('centerCeilingStowage', 'middle-section')">
            <path class="editable-content__background" fill="transparent" d="M433.1,495.9h6.8c0,0,0.1-0.1,0.1-0.2v-41.7c0-0.1,0-0.2-0.1-0.2h-6.8c0,0-0.1,0.1-0.1,0.2v41.7  C433,495.9,433.1,495.9,433.1,495.9z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('centerCeilingStowage', 'aft-section')">
            <path class="editable-content__background" fill="transparent" d="M433.1,789.8h6.8c0,0,0.1-0.1,0.1-0.2V748c0-0.1,0-0.2-0.1-0.2h-6.8c0,0-0.1,0.1-0.1,0.2v41.7  C433,789.8,433.1,789.8,433.1,789.8z"/>
        </g>
        <g class="editable-content" :class="contentClasses('sidewallDecorCover', 'economy', 'selectedColors')">
            <path class="editable-content__background pulse-item" fill="transparent" d="M385.8,819.2c-0.8,0-1.5-0.7-1.5-1.5V172.8c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v644.9  C387.3,818.5,386.6,819.2,385.8,819.2z"/>
            <path class="editable-content__background pulse-item" fill="transparent" d="M486.7,819.1c-0.8,0-1.5-0.7-1.5-1.5V172.7c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v644.9  C488.2,818.4,487.6,819.1,486.7,819.1z"/>
        </g>
    </svg>
</template>

<script>
import { defineComponent } from 'vue'
import { planeContentProps, usePlaneContentClass } from '@/composables/plane-content'

export default defineComponent({
  props: {
    ...planeContentProps()
  },
  setup (props) {
    const { contentClasses } = usePlaneContentClass(props)

    return { contentClasses }
  }
})
</script>
