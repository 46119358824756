<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size ? size : width"
    :height="size ? size : height"
    :viewBox="viewBox"
    role="presentation"
  >
    <g :fill="iconColor">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    },
    size: {
      type: [Number, Boolean],
      default: false
    },
    viewBox: {
      type: [String],
      default: '0 0 24 24'
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;

  &:focus {
    outline: none;
  }
}
</style>
