import { computed } from 'vue'

export const planeContentProps = () => ({
  activeConfiguration: {
    type: Object,
    default: () => {}
  },
  activeFeatureSlug: {
    type: String,
    default: ''
  }
})

export function usePlaneContentClass (props) {
  const contentClasses = computed(() => {
    return (featureSlug, selectionSlug, selectionObj = 'selectedPositions') => {
      let selectionActive
      let tabActive = false

      if (selectionObj === 'selectedColors') {
        selectionActive = props.activeConfiguration[props.activeFeatureSlug][selectionObj] && props.activeConfiguration[props.activeFeatureSlug][selectionObj][selectionSlug] && props.activeConfiguration[props.activeFeatureSlug][selectionObj][selectionSlug].length > 0
        tabActive = props.activeConfiguration[props.activeFeatureSlug].activeTab && props.activeConfiguration[props.activeFeatureSlug].activeTab === selectionSlug
      } else {
        selectionActive = props.activeConfiguration[props.activeFeatureSlug][selectionObj] && props.activeConfiguration[props.activeFeatureSlug][selectionObj].includes(selectionSlug)
      }
      const isSelected = selectionActive && featureSlug === props.activeFeatureSlug

      return {
        'is-active': featureSlug === props.activeFeatureSlug && !isSelected,
        'is-selected': isSelected,
        'is-tab-active': tabActive
      }
    }
  })

  return {
    contentClasses
  }
}
