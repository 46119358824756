import { createStore } from 'vuex'

export default createStore({
  state: {
    windowWidth: window.innerWidth,
    activeConfiguration: false
  },
  mutations: {
    setWindowWidth (state, payload) {
      state.windowWidth = payload
    },
    setActiveConfiguration (state, payload) {
      state.activeConfiguration = payload
    }
  },
  actions: {
  },
  getters: {
    windowWidth (state) {
      return state.windowWidth
    },
    activeConfiguration (state) {
      return state.activeConfiguration
    }
  },
  modules: {
  }
})
