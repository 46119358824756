export default function checkForTouch () {
  const supportsTouch =
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0

  if (supportsTouch) {
    document.body.classList.add('touch')
  } else {
    document.body.classList.add('no-touch')
  }
}
