<template>
    <div class="feature-navigation">
        <div class="container feature-navigation__inner">
            <router-link :to="{ name: 'Start' }" class="feature-navigation__item color-black">
                <icon>
                    <icon-arrow-left />
                </icon>
            </router-link>

            <div class="feature-navigation__center">
                <div class="feature-navigation__item" :class="{'is-active': feature.slug === activeFeature, 'is-selected': feature.hasSelection}" v-for="feature in navigationFeatures" :key="feature.slug" @click="featureClickHandler(feature.slug)">
                    {{ feature.label }}
                </div>
            </div>

            <div class="feature-navigation__item color-black" @click="resetClickHandler">
                <icon>
                    <icon-reset />
                </icon>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/Icon.vue'
import IconArrowLeft from '@/components/icons/IconArrowLeft.vue'
import IconReset from '@/components/icons/IconReset.vue'

export default {
  name: 'FeatureNavigation',
  components: {
    Icon,
    IconArrowLeft,
    IconReset
  },
  props: {
    features: {
      type: Array,
      default: () => []
    },
    activeFeature: {
      type: String,
      default: ''
    },
    activeConfiguration: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    navigationFeatures () {
      const navigationItems = []
      this.features.forEach(feature => {
        let hasSelection = false
        if (this.activeConfiguration[feature.slug].selectedPositions) {
          hasSelection = this.activeConfiguration[feature.slug].selectedPositions.length > 0
        } else if (this.activeConfiguration[feature.slug].selectedColors) {
          let selectedColors = []
          for (const i in this.activeConfiguration[feature.slug].selectedColors) {
            selectedColors = [...selectedColors, ...this.activeConfiguration[feature.slug].selectedColors[i]]
          }
          hasSelection = selectedColors.length > 0
        }
        navigationItems.push({
          slug: feature.slug,
          label: feature.label,
          hasSelection: hasSelection
        })
      })
      return navigationItems
    }
  },
  methods: {
    featureClickHandler (slug) {
      this.$emit('featureClick', slug)
    },
    resetClickHandler () {
      this.$emit('resetClick')
    }
  }
}
</script>

<style lang="scss">
    .feature-navigation {
        padding: 12px 0;
        opacity: 0;
        transform: translateY(-50px);
        transition: all 700ms $ease-out-quad;

        .is-visible & {
            opacity: 1;
            transform: none;
        }

        &__inner {
            display: flex;
            justify-content: space-between;
            gap: 13px;
        }

        &__center {
            display: flex;
            gap: 13px;
        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background: $color-grey-light;
            border: 1px solid $color-grey-light;
            height: 56px;
            min-width: 56px;
            border-radius: $border-radius-s;
            padding: 0 16px;
            color: $color-primary;
            line-height: 1;
            transition: $hover-transition;
            cursor: pointer;

            .no-touch &:hover {
                background: $color-grey-medium;
                border-color: $color-grey-medium;
            }

            &.is-active {
                background: $color-primary !important;
                color: $color-white;
                border-color: $color-primary !important;
            }

            &.is-selected {
                border-color: $color-primary !important;
            }
        }
    }
</style>
