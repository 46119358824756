<template>
    <div class="get-layout">
        <div class="get-layout__logo">
            <img :src="`${publicPath}img/logo.svg`" alt="FACC" />
        </div>
        <div class="get-layout__inner">
            <div v-if="!saved">
                <div class="get-layout__title h1 color-primary">Enter your email to get your personal layout</div>
                <input class="get-layout__input" :class="{'error' : emailInvalid}" type="email" placeholder="Type your email here" v-model="email" @input="emailInputHandler">
                <div class="get-layout__button button button--primary" @click="saveHandler">Send layout</div>
                <router-link :to="{ name: 'Configurator', params: {type: activeConfiguration.blueprint.type, layout: activeConfiguration.blueprint.layout} }" class="get-layout__button button button--ghost">Go back to the configurator</router-link>
            </div>
            <div v-else>
                <div class="get-layout__title get-layout__title--success h1 color-primary">Thank you!</div>
                <router-link :to="{ name: 'Start' }" class="get-layout__button button button--ghost">Configure another</router-link>
            </div>
        </div>
        <help-button />
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import HelpButton from '@/components/HelpButton.vue'

export default {
  name: 'GetLayout',
  components: {
    HelpButton
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      email: '',
      emailInvalid: false,
      saved: false,
      sentOnce: false
    }
  },
  computed: {
    ...mapGetters(['activeConfiguration'])
  },
  methods: {
    saveHandler () {
      this.sentOnce = true
      if (this.email === '' || !this.email.includes('@')) {
        this.emailInvalid = true
      } else {
        this.saveConfiguration()
      }
    },
    emailInputHandler () {
      if (!this.sentOnce) return
      if (this.email === '' || !this.email.includes('@')) {
        this.emailInvalid = true
      } else {
        this.emailInvalid = false
      }
    },
    async saveConfiguration () {
      try {
        const configUrl = this.generateConfigUrl()
        await axios.post('/api/save.php', {
          email: this.email,
          url: configUrl
        })
        this.saved = true
        this.$store.commit('setActiveConfiguration', false)
      } catch (error) {
        console.error(error)
      }
    },
    generateConfigUrl () {
      let urlParams = '?'

      for (const key in this.activeConfiguration) {
        const item = this.activeConfiguration[key]
        if (key === 'blueprint') {
          urlParams += `type=${item.type}&layout=${item.layout}&`
        } else {
          if (item.selectedPositions && item.selectedPositions.length > 0) {
            urlParams += `${key}_positions=${item.selectedPositions.join(',')}&`
          }
          if (item.selectedColors) {
            const classColors = []
            for (const c in item.selectedColors) {
              if (item.selectedColors[c].length > 0) {
                classColors.push(`${c}_${item.selectedColors[c].join(',')}`)
              }
            }
            if (classColors.length > 0) {
              urlParams += `${key}_colors=${classColors.join('|')}&`
            }
          }
        }
      }
      if (urlParams.endsWith('&')) urlParams = urlParams.slice(0, -1)
      return `${window.location.protocol}//${window.location.host}${urlParams}`
    }
  }
}
</script>

<style lang="scss">
.get-layout {
    $block: &;
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__logo {
        position: absolute;
        top: 44px;
        left: 50%;
        transform: translateX(-50%);
    }

    &__inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 160px 20px;
    }

    &__title {
        max-width: 800px;
        &--success {
            margin-bottom: 24px;
        }
    }

    &__input {
        display: block;
        outline: none;
        border: none;
        border-bottom: 2px solid $color-grey-medium;
        font-size: 48px;
        padding: 5px 40px;
        width: 100%;
        text-align: center;
        margin: 0 auto 64px;
        max-width: 600px;

        &::placeholder {
            color: rgba($color-grey, 0.4);
        }

        &.error {
            border-color: red;
        }
    }

    &__button {
        width: 100%;
        max-width: 446px;

        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }
}
</style>
