<template>
    <div class="video-player">
        <div class="video-player__cover" v-if="video.poster && !videoStarted" @click="coverClickHandler">
            <img class="video-player__cover__image" :src="video.poster" alt="">
            <div class="video-player__cover__button">
                <icon>
                    <icon-play />
                </icon>
            </div>
        </div>
        <video class="video-player__video" controls preload="auto" ref="videoEl">
            <source :src="video.source" type="video/mp4" />
        </video>
    </div>
</template>

<script>
import Icon from '@/components/Icon.vue'
import IconPlay from '@/components/icons/IconPlay.vue'

export default {
  name: 'VideoPlayer',
  components: {
    Icon,
    IconPlay
  },
  props: {
    video: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      videoStarted: false
    }
  },
  watch: {
    video: function () {
      this.videoStarted = false
      this.$refs.videoEl.pause()
    }
  },
  methods: {
    coverClickHandler () {
      this.videoStarted = true
      this.$refs.videoEl.load()
      this.$refs.videoEl.play()
    }
  }
}
</script>

<style lang="scss">
.video-player {
    $block: &;
    position: relative;
    border-radius: $border-radius-s;
    overflow: hidden;
    line-height: 0;

    &:after {
        content: '';
        display: block;
        padding-bottom: calc(250 * 100% / 446);
    }

    &__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $border-radius-s;
        width: 100%;
    }

    &__cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        cursor: pointer;

        .no-touch &:hover {
            #{$block}__cover {
                &__button {
                    background: $color-primary-hover;
                }
            }
        }

        &__image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &__button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 64px;
            height: 64px;
            border-radius: 100%;
            background: $color-primary;
            border: 2px solid $color-white;
            color: $color-white;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: $hover-transition;
        }
    }
}
</style>
