import { createRouter, createWebHistory } from 'vue-router'
import Start from '@/views/Start.vue'
import Configurator from '@/views/Configurator.vue'
import GetLayout from '@/views/GetLayout.vue'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Start',
    component: Start
  },
  {
    path: '/configurator/:type/:layout',
    name: 'Configurator',
    component: Configurator
  },
  {
    path: '/getlayout',
    name: 'GetLayout',
    component: GetLayout,
    beforeEnter: (to, from) => {
      // reject the navigation
      if (!store.getters.activeConfiguration) {
        return { name: 'Start' }
      }
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
