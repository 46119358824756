<template>
    <g>
        <path d="M12,1.5C6.2,1.5,1.5,6.2,1.5,12c0,5.8,4.7,10.5,10.5,10.5c5.8,0,10.5-4.7,10.5-10.5C22.5,6.2,17.8,1.5,12,1.5z
            M12,21.5c-5.2,0-9.5-4.3-9.5-9.5S6.8,2.5,12,2.5s9.5,4.3,9.5,9.5S17.2,21.5,12,21.5z"/>
        <path d="M12.5,6.5c-0.8-0.1-1.7,0-2.4,0.4C9.4,7.4,8.9,8.1,8.6,8.8c-0.1,0.3,0,0.5,0.3,0.6c0.3,0.1,0.5,0,0.6-0.3
            c0.2-0.6,0.6-1,1.1-1.3c0.5-0.3,1.1-0.4,1.7-0.3c0.6,0.1,1.1,0.4,1.5,0.9c0.4,0.5,0.6,1,0.6,1.6c0,1.3-1.9,2.3-2.7,2.5
            c-0.3,0.1-0.4,0.4-0.3,0.6c0.1,0.2,0.3,0.3,0.5,0.3c0.1,0,0.1,0,0.2,0c0.1,0,3.3-1.1,3.3-3.5c0-0.8-0.3-1.6-0.8-2.3
            S13.3,6.7,12.5,6.5z"/>
        <path d="M12,16.5L12,16.5c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5S12.3,16.5,12,16.5z"/>
    </g>
</template>
