<template>
  <div class="start" :class="{'is-visible': visible}">
    <div class="container">
        <div class="start__top">
            <div class="start__top__logo">
                <img :src="`${publicPath}img/logo.svg`" alt="FACC">
            </div>
            <h1 class="start__top__title h1 color-primary">
                Choose your aircraft type
            </h1>
        </div>

        <div class="start__switch">
            <type-switch :options="AIRCRAFT_TYPES" :active-option="activeType" @optionClick="optionClickHandler" />
        </div>

        <div class="start__airplane">
            <div class="start__airplane__inner">
                <img :src="`${publicPath}img/planes/${fullActiveType.image}`" :alt="fullActiveType.label">
            </div>
        </div>

        <div class="start__cards">
            <layout-selection :active-type="activeType" :cards="fullActiveType.layouts" />
        </div>
    </div>

    <div class="start__bottom" v-if="showBottom">
        <a :href="link.href" :target="link.target || ''" class="start__bottom__link uptitle" v-for="(link, index) in bottomLinks" :key="index">
            {{ link.label }}
            <span class="start__bottom__link__icon color-primary">
                <icon :size="16">
                    <icon-external-link />
                </icon>
            </span>
        </a>
    </div>
  </div>
</template>

<script>
import AIRCRAFT_TYPES from '@/data/aircraft-types.js'
import TypeSwitch from '@/components/TypeSwitch.vue'
import LayoutSelection from '@/components/LayoutSelection.vue'
import Icon from '@/components/Icon.vue'
import IconExternalLink from '@/components/icons/IconExternalLink.vue'

export default {
  name: 'Start',
  components: {
    TypeSwitch,
    LayoutSelection,
    Icon,
    IconExternalLink
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      visible: false,

      activeType: false,
      AIRCRAFT_TYPES,
      showBottom: false,
      bottomLinks: [
        {
          href: '#',
          label: 'About FACC',
          target: '_blank'
        },
        {
          href: '#',
          label: 'Cabin of the future',
          target: '_blank'
        }
      ]
    }
  },
  created () {
    this.activeType = this.AIRCRAFT_TYPES[0].slug
  },
  mounted () {
    setTimeout(() => {
      this.visible = true
    }, 100)
  },
  computed: {
    fullActiveType () {
      return this.AIRCRAFT_TYPES.find(type => type.slug === this.activeType)
    }
  },
  methods: {
    optionClickHandler (e) {
      this.activeType = e
    }
  }
}
</script>

<style lang="scss">
.start {
  $block: &;
  padding-bottom: 140px;

  &__top {
    position: relative;
    margin-top: 44px;
    margin-bottom: 60px;
    text-align: center;

    &__logo {
        position: absolute;
        top: 0;
        left: 0;
    }

    &__title {
        transition: all 500ms $ease-out-quad;
        opacity: 0;
        transform: translateY(20px);

        .is-visible & {
          transform: none;
          opacity: 1;
      }
    }
  }

  &__switch {
    text-align: center;
    margin-bottom: 70px;

    transition: all 500ms $ease-out-quad 200ms;
    opacity: 0;
    transform: translateY(20px);

    .is-visible & {
        transform: none;
        opacity: 1;
    }
  }

  &__airplane {
      text-align: center;
      margin-bottom: 128px;
      line-height: 0;
      transform: translate(100px, 200px) rotate(5deg);
      opacity: 0;
      transform-origin: bottom right;
      transition: all 2s $ease-out-quint 300ms;

      .is-visible & {
          transform: none;
          opacity: 1;
      }

      &__inner {
        animation: floating-plane 10s linear infinite;
      }

      img {
          max-width: 100%;
      }
  }

  &__bottom {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      border-top: 2px solid $color-grey-medium;
      z-index: 10;
      background: white;

      &__link {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          text-align: center;
          color: $color-black;
          padding: 24px;
          transition: $hover-transition;

          &:not(:last-child) {
              border-right: 2px solid $color-grey-medium;
          }

          .no-touch &:hover {
              color: $color-primary;
          }

          &__icon {
              line-height: 1;
              margin-left: 8px;
          }
      }
  }
}

@keyframes floating-plane {
    0%,
    100% {
        transform: translate(0, -10px);
    }
    50% {
        transform: translate(0, 10px);
    }
}
</style>
