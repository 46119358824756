<template>
    <div class="layout-selection">
        <div class="layout-selection__card" v-for="(card, index) in cards" :key="index">
            <div class="layout-selection__card__wrapper"  :class="{'is-hidden' : cardsHidden}">
                <router-link :to="{ name: 'Configurator', params: {type: activeType, layout: card.slug} }" class="layout-selection__card__inner">
                    <div class="layout-selection__title h3">{{ card.label }}</div>
                    <div class="layout-selection__text copy">{{ card.description }}</div>
                    <div class="layout-selection__icon">
                        <icon>
                            <icon-arrow-right />
                        </icon>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/Icon.vue'
import IconArrowRight from '@/components/icons/IconArrowRight.vue'

export default {
  name: 'LayoutSelection',
  components: {
    Icon,
    IconArrowRight
  },
  props: {
    activeType: {
      type: String,
      default: ''
    },
    cards: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      cardsHidden: false
    }
  },
  watch: {
    activeType () {
      this.cardsHidden = true
      setTimeout(() => {
        this.cardsHidden = false
      }, 10)
    }
  }
}
</script>

<style lang="scss">
    .layout-selection {
        $block: &;

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;

        &__card {
            opacity: 0;
            transform: translateY(10px);
            transition: all 500ms $ease-out-quad;

            .is-visible & {
                opacity: 1;
                transform: none;
            }

            @for $i from 1 through 3 {
                &:nth-child(#{$i}) {
                    transition-delay: (200ms * $i) + 400ms;

                    #{$block}__card__wrapper {
                       transition-delay: (200ms * $i);
                    }
                }
            }

            &__wrapper {
                display: flex;
                height: 100%;
                transition: all 500ms $ease-out-quad;

                &.is-hidden {
                    opacity: 0;
                    transform: translateY(10px);
                    transition-delay: 0ms !important;
                    transition: none !important;
                }
            }

            &__inner {
                display: flex;
                flex-direction: column;
                padding: 24px;
                border-radius: $border-radius-s;
                border: 2px solid $color-grey-medium;
                transition: $hover-transition;

                .no-touch &:hover {
                    border-color: $color-primary;
                }
            }
        }

        &__title {
            margin-bottom: 16px;
        }

        &__text {
            margin-bottom: 24px;
        }

        &__icon {
            width: 32px;
            height: 32px;
            background: $color-primary;
            color: $color-white;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: auto;
        }
    }
</style>
