<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="875.1px" height="1118px" viewBox="0 0 875.1 1118" enable-background="new 0 0 875.1 1118" xml:space="preserve">
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M552.3,545.8 c0.2,11.7,1.3,12.7,3.2,17.5c0.1,0.2,0.3,0.3,0.5,0.3s0.4-0.1,0.5-0.3c1.9-4.8,3.2-5.8,3.4-17.5"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M639.2,555.4 c0.2,14.3,1.6,16.6,3.9,22.4c0.1,0.3,0.4,0.4,0.7,0.4c0.3,0,0.5-0.2,0.7-0.4c2.3-5.5,3.7-7,4.1-19.4"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M575.5,545.9 c0.1,6.5,0.9,7.1,2.2,9.7c0.1,0.1,0.2,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2c1.3-2.7,2.2-3.2,2.3-9.7H575.5z"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M324.8,545.8 c-0.2,11.7-1.3,12.7-3.2,17.5c-0.1,0.2-0.3,0.4-0.5,0.4s-0.4-0.1-0.5-0.4c-1.9-4.8-3.2-5.8-3.4-17.5"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M237.8,555.4 c-0.2,14.3-1.6,16.6-3.9,22.4c-0.1,0.3-0.4,0.4-0.7,0.4c-0.3,0-0.5-0.2-0.7-0.4c-2.2-5.5-3.7-7-4.1-19.4"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M301.5,545.9 c-0.1,6.5-0.9,7.1-2.2,9.7c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.3-0.1-0.4-0.2c-1.3-2.7-2.2-3.2-2.3-9.7H301.5z"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M489.3,390.4 c0,3.8,2.1,5.5,5.6,7.2l175.9,88l193.6,97c0,0,9.6,4.5,9.6,18.6c-0.1,14.1,0,34.2,0,34.2h-3l-0.2-14l-127.5-36.1l-139.1-39.4H489.3" />
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M597.6,449 c8-5.2,9-30.7,9.5-46.8c0.2-8.7-0.2-17.4-1.3-26.1c-0.9-6.3-2-12.8-3.8-16.2h-42.1c0,0-6,8.7-5.8,30.4c0.1,12.9,1.2,25.8,3.3,38.5" />
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M387.8,390.4 c0,3.8-2.1,5.5-5.6,7.2l-175.9,88l-193.6,97c0,0-9.6,4.5-9.6,18.6c0,14.1,0,34.2,0,34.2h3l0.2-14l127.5-36.1L273,545.9h114.8"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M279.5,449 c-8-5.2-9-30.7-9.5-46.8c-0.2-8.7,0.2-17.4,1.3-26.1c0.8-6.3,2-12.8,3.8-16.2h42c0,0,6,8.7,5.8,30.4c-0.1,12.9-1.2,25.8-3.3,38.5"/>
        <path class="plane-body" opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M430.9,3.8 c-38.8,31-43.8,124.5-43.8,124.5c0,99.4,0,376.6,0,376.6s-0.7,343.7,0,343c1.3,94.7,51.3,226.1,51.3,226.1h-0.2 c0,0,49.8-131.5,51.1-226.1c0.7,0.7,0-343,0-343s0-277.2,0-376.6c0,0-3.7-93.4-42.4-124.4C442.2,0.1,435.5,0.1,430.9,3.8z"/>
        <g class="editable-content" :class="contentClasses('sidewallDecorCover', 'business', 'selectedColors')">
            <rect class="editable-content__area" fill="transparent" x="387.3" y="133" width="102.5" height="158.6"/>
            <path class="editable-content__background pulse-item" fill="transparent" d="M387.8,287.3c-0.8,0-1.5-0.7-1.5-1.5v-132c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v132  C389.3,286.7,388.6,287.3,387.8,287.3z"/>
            <path class="editable-content__background pulse-item" fill="transparent" d="M488.6,287.5c-0.8,0-1.5-0.7-1.5-1.5V154c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v132  C490.1,286.8,489.4,287.5,488.6,287.5z"/>
        </g>
        <g class="editable-content" :class="contentClasses('sidewallDecorCover', 'premium-economy', 'selectedColors')">
            <rect class="editable-content__area" fill="transparent" x="387.3" y="291.6" width="102.5" height="97.2"/>
            <path class="editable-content__background pulse-item" fill="transparent" d="M387.8,384.7c-0.8,0-1.5-0.7-1.5-1.5v-86.6c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v86.6  C389.3,384,388.6,384.7,387.8,384.7z"/>
            <path class="editable-content__background pulse-item" fill="transparent" d="M488.6,384.8c-0.8,0-1.5-0.7-1.5-1.5v-86.6c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v86.6  C490.1,384.1,489.4,384.8,488.6,384.8z"/>
        </g>
        <g class="editable-content" :class="contentClasses('sidewallDecorCover', 'economy', 'selectedColors')">
            <rect class="editable-content__area" fill="transparent" x="387.3" y="388.8" width="102.5" height="437.3"/>
            <path class="editable-content__background pulse-item" fill="transparent" d="M387.8,810.6c-0.8,0-1.5-0.7-1.5-1.5V394.9c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v414.2  C389.3,809.9,388.6,810.6,387.8,810.6z"/>
            <path class="editable-content__background pulse-item" fill="transparent" d="M488.6,811c-0.8,0-1.5-0.7-1.5-1.5V395.3c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v414.2  C490.1,810.3,489.4,811,488.6,811z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('partition', 'premium-economy')">
            <path class="editable-content__background" fill="#C4C4C4" d="M432.2,291.6L432.2,291.6c0-0.7-0.6-1.3-1.3-1.3h-39.2c-0.7,0-1.3,0.6-1.3,1.3l0,0c0,0.7,0.6,1.3,1.3,1.3 h39.2C431.6,292.9,432.2,292.3,432.2,291.6z"/>
            <path class="editable-content__background" fill="#C4C4C4" d="M486.3,291.6L486.3,291.6c0-0.7-0.6-1.3-1.3-1.3h-39.2c-0.7,0-1.3,0.6-1.3,1.3l0,0c0,0.7,0.6,1.3,1.3,1.3 h39.2C485.8,292.9,486.3,292.3,486.3,291.6z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('partition', 'economy-front')">
            <path class="editable-content__background" fill="#C4C4C4" d="M432.2,388.8L432.2,388.8c0-0.7-0.6-1.3-1.3-1.3h-39.2c-0.7,0-1.3,0.6-1.3,1.3l0,0c0,0.7,0.6,1.3,1.3,1.3 h39.2C431.6,390.1,432.2,389.5,432.2,388.8z"/>
            <path class="editable-content__background" fill="#C4C4C4" d="M486.3,388.8L486.3,388.8c0-0.7-0.6-1.3-1.3-1.3h-39.2c-0.7,0-1.3,0.6-1.3,1.3l0,0c0,0.7,0.6,1.3,1.3,1.3 h39.2C485.8,390.1,486.3,389.5,486.3,388.8z"/>
        </g>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M407.6,973.2l-175.7,121.1v23 l202.9-52.4h7.1l202.9,52.4v-23L469,973.2"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M770.4,593v-6.8l-166.8-52.9 H489.3v12.5h114.8L770.4,593z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M575,437.7v-55.4 c0-1.7,0.7-3.2,1.9-4.4c1.2-1.1,2.8-1.8,4.4-1.7c3.4-0.1,6.3,2.7,6.4,6.1v61.7"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M505.3,402.8l-7,11.3 l167.2,83.8l183,90.9l5.8-11.2l-183.6-92L505.3,402.8z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M765.4,584.6l5-16.2 l-117.5-38.3l-39.3-12.8l-6.9,17.1l40.7,12.9L765.4,584.6z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M489.3,533.4h111.3v-16.1 h-48.3v6.1h-63V533.4z"/>
        <path opacity="0.4" fill="none" stroke="#33546C" stroke-width="0.5" d="M438.4,1082.4 c-4.2-23.9-7-56.3-8.4-112.5c-1.6-64.6,3.6-99.2,8.4-116.3h-0.2c4.8,17.1,10,51.7,8.4,116.3c-1.4,56.3-4.2,88.6-8.4,112.5"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.75" d="M557.3,365.9 c0.6-2.1,1.5-4,2.6-5.9H602c0.8,1.8,1.5,3.7,1.9,5.7l-46.6,0.1L557.3,365.9z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M106.6,593v-6.8l166.8-52.9 h114.4v12.5H273L106.6,593z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M302,437.7v-55.4 c-0.1-3.4-2.9-6.2-6.4-6.1c-1.7,0-3.3,0.6-4.4,1.7s-1.9,2.7-1.9,4.4v61.7"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M371.8,402.8l7,11.3 l-167.2,83.8l-183,90.9l-5.8-11.2l183.6-92L371.8,402.8z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M111.6,584.6l-5-16.2 l117.5-38.3l39.3-12.8l6.9,17.1l-40.7,12.9L111.6,584.6z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M387.8,533.4H276.5v-16.1h48.3 v6.1h62.9V533.4z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.75" d="M319.7,365.9 c-0.6-2.1-1.5-4-2.6-5.9h-42.1c-0.9,1.8-1.5,3.8-2,5.7L319.7,365.9L319.7,365.9z"/>
        <path opacity="0.4" fill="none" stroke="#33546C" stroke-width="0.75" d="M463.4,49l-5.3,10.5l7.5,10.2 h9.3L463.4,49z"/>
        <path opacity="0.4" fill="none" stroke="#33546C" stroke-width="0.75" d="M476.3,73h-8.9l4.5,12.5h7 L476.3,73z"/>
        <path opacity="0.4" fill="none" stroke="#33546C" stroke-width="0.75" d="M413.1,49l5.3,10.5l-7.5,10.2 h-9.3L413.1,49z"/>
        <path opacity="0.4" fill="none" stroke="#33546C" stroke-width="0.75" d="M400.3,73h8.9l-4.4,12.5h-7 L400.3,73z"/>
        <g opacity="0.4">
        <path opacity="0.4" fill="none" stroke="#33546C" stroke-width="0.75" d="M455.3,56.3l4.8-11.2 l-20.5-13.2v14.7L455.3,56.3z"/>
        <path opacity="0.4" fill="none" stroke="#33546C" stroke-width="0.75" d="M436.8,32.1l-20.3,13.1 l4.8,11.2l15.5-9.6V32.1z"/>
        </g>
        <g opacity="0.2">
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M566.8,433.5l-6.2,11.7"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M609.9,455.2v14.6"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M670.8,485.6l-5.3,12.3"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M732.2,516.4l-5.8,11.7"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M793.7,547.2l-5.8,11.5"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M732.2,555.9l-5.8,16.3"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M692.7,543.1l-5.8,16.7"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M652.9,530.1l-5.5,17.1"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M552.3,523.4v10"/>
        </g>
        <g opacity="0.2">
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M310.3,433.5l6.2,11.7"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M267.1,455.2v14.6"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M206.3,485.6l5.3,12.3"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M144.9,516.4l5.8,11.7"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M83.3,547.2l5.8,11.5"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M144.9,555.9l5.8,16.3"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M184.3,543.1l5.8,16.7"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M224.2,530.1l5.5,17.1"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M324.8,523.4v10"/>
        </g>
        <path fill="#FFFFFF" d="M438.7,1035.9c0,0-2.8,2.8-3.6,23.5c-0.5,13.4,2.2,25.5,3.6,28.3h-0.2c1.4-2.8,4.1-14.9,3.6-28.3 c-0.8-20.7-3.6-23.5-3.6-23.5"/>
        <path fill="none" stroke="#33546C" stroke-width="0.75" d="M438.7,1035.9c0,0-2.8,2.8-3.6,23.5c-0.5,13.4,2.2,25.5,3.6,28.3h-0.2 c1.4-2.8,4.1-14.9,3.6-28.3c-0.8-20.7-3.6-23.5-3.6-23.5"/>
        <g>
            <g opacity="0.25">
                <path fill="#737373" d="M432.1,380.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8 C431.8,380.7,432.1,380.5,432.1,380.2z"/>
                <path fill="#737373" d="M417.6,380.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8 C417.3,380.7,417.6,380.5,417.6,380.2z"/>
                <path fill="#737373" d="M403.1,380.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8 C402.8,380.7,403.1,380.5,403.1,380.2z"/>
                <path fill="#737373" d="M486.6,380.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8 C486.4,380.7,486.6,380.5,486.6,380.2z"/>
                <path fill="#737373" d="M472.1,380.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8 C471.9,380.7,472.1,380.5,472.1,380.2z"/>
                <path fill="#737373" d="M457.7,380.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8 C457.4,380.7,457.7,380.5,457.7,380.2z"/>
                <path fill="#737373" d="M432.1,364v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5V364c0,0.3,0.2,0.5,0.5,0.5h11.8 C431.8,364.5,432.1,364.3,432.1,364z"/>
                <path fill="#737373" d="M417.6,364v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5V364c0,0.3,0.2,0.5,0.5,0.5h11.8 C417.3,364.5,417.6,364.3,417.6,364z"/>
                <path fill="#737373" d="M403.1,364v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5V364c0,0.3,0.2,0.5,0.5,0.5h11.8 C402.8,364.5,403.1,364.3,403.1,364z"/>
                <path fill="#737373" d="M486.6,364v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5V364c0,0.3,0.2,0.5,0.5,0.5h11.8 C486.4,364.5,486.6,364.3,486.6,364z"/>
                <path fill="#737373" d="M472.1,364v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5V364c0,0.3,0.2,0.5,0.5,0.5h11.8 C471.9,364.5,472.1,364.3,472.1,364z"/>
                <path fill="#737373" d="M457.7,364v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5V364c0,0.3,0.2,0.5,0.5,0.5h11.8 C457.4,364.5,457.7,364.3,457.7,364z"/>
                <path fill="#737373" d="M432.1,344.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8 C431.8,344.9,432.1,344.6,432.1,344.4z"/>
                <path fill="#737373" d="M417.6,344.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8 C417.3,344.9,417.6,344.6,417.6,344.4z"/>
                <path fill="#737373" d="M403.1,344.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8 C402.8,344.9,403.1,344.6,403.1,344.4z"/>
                <path fill="#737373" d="M486.6,344.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8 C486.4,344.9,486.6,344.6,486.6,344.4z"/>
                <path fill="#737373" d="M472.1,344.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8 C471.9,344.9,472.1,344.6,472.1,344.4z"/>
                <path fill="#737373" d="M457.7,344.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8 C457.4,344.9,457.7,344.6,457.7,344.4z"/>
                <path fill="#737373" d="M432.1,328.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8 C431.8,328.7,432.1,328.4,432.1,328.2z"/>
                <path fill="#737373" d="M417.6,328.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8 C417.3,328.7,417.6,328.4,417.6,328.2z"/>
                <path fill="#737373" d="M403.1,328.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8 C402.8,328.7,403.1,328.4,403.1,328.2z"/>
                <path fill="#737373" d="M486.6,328.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8 C486.4,328.7,486.6,328.4,486.6,328.2z"/>
                <path fill="#737373" d="M472.1,328.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8 C471.9,328.7,472.1,328.4,472.1,328.2z"/>
                <path fill="#737373" d="M457.7,328.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8 C457.4,328.7,457.7,328.4,457.7,328.2z"/>
                <path fill="#737373" d="M432.1,312v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5V312c0,0.3,0.2,0.5,0.5,0.5h11.8 C431.8,312.5,432.1,312.2,432.1,312z"/>
                <path fill="#737373" d="M417.6,312v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5V312c0,0.3,0.2,0.5,0.5,0.5h11.8 C417.3,312.5,417.6,312.2,417.6,312z"/>
                <path fill="#737373" d="M403.1,312v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5V312c0,0.3,0.2,0.5,0.5,0.5h11.8 C402.8,312.5,403.1,312.2,403.1,312z"/>
                <path fill="#737373" d="M486.6,312v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5V312c0,0.3,0.2,0.5,0.5,0.5h11.8 C486.4,312.5,486.6,312.2,486.6,312z"/>
                <path fill="#737373" d="M472.1,312v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5V312c0,0.3,0.2,0.5,0.5,0.5h11.8 C471.9,312.5,472.1,312.2,472.1,312z"/>
                <path fill="#737373" d="M457.7,312v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8c-0.3,0-0.5,0.2-0.5,0.5V312c0,0.3,0.2,0.5,0.5,0.5h11.8 C457.4,312.5,457.7,312.2,457.7,312z"/>
            </g>
            <g opacity="0.5">
                <path fill="#737373" d="M424.1,279.3l4-11.1c0.1-0.3,0-0.6-0.3-0.7l-11.1-4c-0.3-0.1-0.6,0-0.7,0.3l-4,11.1 c-0.1,0.3,0,0.6,0.3,0.7l11.1,4C423.7,279.7,424,279.5,424.1,279.3z"/>
                <path fill="#737373" d="M405.5,272.5l4-11.1c0.1-0.3,0-0.6-0.3-0.7l-11.1-4c-0.3-0.1-0.6,0-0.7,0.3l-4,11.1 c-0.1,0.3,0,0.6,0.3,0.7l11.1,4C405.1,272.9,405.4,272.8,405.5,272.5z"/>
                <path fill="#737373" d="M424.1,257.1l4-11.1c0.1-0.3,0-0.6-0.3-0.7l-11.1-4c-0.3-0.1-0.6,0-0.7,0.3l-4,11.1 c-0.1,0.3,0,0.6,0.3,0.7l11.1,4C423.7,257.5,424,257.4,424.1,257.1z"/>
                <path fill="#737373" d="M405.5,250.4l4-11.1c0.1-0.3,0-0.6-0.3-0.7l-11.1-4c-0.3-0.1-0.6,0-0.7,0.3l-4,11.1 c-0.1,0.3,0,0.6,0.3,0.7l11.1,4C405.1,250.8,405.4,250.6,405.5,250.4z"/>
                <path fill="#737373" d="M424.1,234.9l4-11.1c0.1-0.3,0-0.6-0.3-0.7l-11.1-4c-0.3-0.1-0.6,0-0.7,0.3l-4,11.1 c-0.1,0.3,0,0.6,0.3,0.7l11.1,4C423.7,235.3,424,235.2,424.1,234.9z"/>
                <path fill="#737373" d="M405.5,228.2l4-11.1c0.1-0.3,0-0.6-0.3-0.7l-11.1-4c-0.3-0.1-0.6,0-0.7,0.3l-4,11.1 c-0.1,0.3,0,0.6,0.3,0.7l11.1,4C405.1,228.6,405.4,228.5,405.5,228.2z"/>
                <path fill="#737373" d="M424.1,212.8l4-11.1c0.1-0.3,0-0.6-0.3-0.7l-11.1-4c-0.3-0.1-0.6,0-0.7,0.3l-4,11.1 c-0.1,0.3,0,0.6,0.3,0.7l11.1,4C423.7,213.2,424,213,424.1,212.8z"/>
                <path fill="#737373" d="M405.5,206l4-11.1c0.1-0.3,0-0.6-0.3-0.7l-11.1-4c-0.3-0.1-0.6,0-0.7,0.3l-4,11.1c-0.1,0.3,0,0.6,0.3,0.7 l11.1,4C405.1,206.4,405.4,206.3,405.5,206z"/>
                <path fill="#737373" d="M483.4,264.8l-4-11.1c-0.1-0.3-0.4-0.4-0.7-0.3l-11.1,4c-0.3,0.1-0.4,0.4-0.3,0.7l4,11.1 c0.1,0.3,0.4,0.4,0.7,0.3l11.1-4C483.4,265.3,483.5,265,483.4,264.8z"/>
                <path fill="#737373" d="M465,271.5l-4-11.1c-0.1-0.3-0.4-0.4-0.7-0.3l-11.1,4c-0.3,0.1-0.4,0.4-0.3,0.7l4,11.1 c0.1,0.3,0.4,0.4,0.7,0.3l11.1-4C464.9,272,465.1,271.7,465,271.5z"/>
                <path fill="#737373" d="M483.4,242.6l-4-11.1c-0.1-0.3-0.4-0.4-0.7-0.3l-11.1,4c-0.3,0.1-0.4,0.4-0.3,0.7l4,11.1 c0.1,0.3,0.4,0.4,0.7,0.3l11.1-4C483.4,243.2,483.5,242.9,483.4,242.6z"/>
                <path fill="#737373" d="M465,249.3l-4-11.1c-0.1-0.3-0.4-0.4-0.7-0.3l-11.1,4c-0.3,0.1-0.4,0.4-0.3,0.7l4,11.1 c0.1,0.3,0.4,0.4,0.7,0.3l11.1-4C464.9,249.9,465.1,249.6,465,249.3z"/>
                <path fill="#737373" d="M483.4,220.4l-4-11.1c-0.1-0.3-0.4-0.4-0.7-0.3l-11.1,4c-0.3,0.1-0.4,0.4-0.3,0.7l4,11.1 c0.1,0.3,0.4,0.4,0.7,0.3l11.1-4C483.4,221,483.5,220.7,483.4,220.4z"/>
                <path fill="#737373" d="M465,227.1l-4-11.1c-0.1-0.3-0.4-0.4-0.7-0.3l-11.1,4c-0.3,0.1-0.4,0.4-0.3,0.7l4,11.1 c0.1,0.3,0.4,0.4,0.7,0.3l11.1-4C464.9,227.7,465.1,227.4,465,227.1z"/>
                <path fill="#737373" d="M483.4,198.2l-4-11.1c-0.1-0.3-0.4-0.4-0.7-0.3l-11.1,4c-0.3,0.1-0.4,0.4-0.3,0.7l4,11.1 c0.1,0.3,0.4,0.4,0.7,0.3l11.1-4C483.4,198.8,483.5,198.5,483.4,198.2z"/>
                <path fill="#737373" d="M465,205l-4-11.1c-0.1-0.3-0.4-0.4-0.7-0.3l-11.1,4c-0.3,0.1-0.4,0.4-0.3,0.7l4,11.1 c0.1,0.3,0.4,0.4,0.7,0.3l11.1-4C464.9,205.5,465.1,205.2,465,205z"/>
            </g>
            <path opacity="0.125" fill="#737373" d="M432.1,425.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C431.8,425.9,432.1,425.7,432.1,425.4z"/>
            <path opacity="0.125" fill="#737373" d="M417.6,425.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C417.3,425.9,417.6,425.7,417.6,425.4z"/>
            <path opacity="0.125" fill="#737373" d="M403.1,425.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C402.8,425.9,403.1,425.7,403.1,425.4z"/>
            <path opacity="0.125" fill="#737373" d="M486.6,425.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C486.4,425.9,486.6,425.7,486.6,425.4z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,425.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,425.9,472.1,425.7,472.1,425.4z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,425.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,425.9,457.7,425.7,457.7,425.4z"/>
            <path opacity="0.125" fill="#737373" d="M432.1,441.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C431.8,442.1,432.1,441.9,432.1,441.6z"/>
            <path opacity="0.125" fill="#737373" d="M417.6,441.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C417.3,442.1,417.6,441.9,417.6,441.6z"/>
            <path opacity="0.125" fill="#737373" d="M403.1,441.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C402.8,442.1,403.1,441.9,403.1,441.6z"/>
            <path opacity="0.125" fill="#737373" d="M486.6,441.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C486.4,442.1,486.6,441.9,486.6,441.6z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,441.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,442.1,472.1,441.9,472.1,441.6z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,441.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,442.1,457.7,441.9,457.7,441.6z"/>
            <path opacity="0.125" fill="#737373" d="M432.1,409.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C431.8,409.7,432.1,409.5,432.1,409.2z"/>
            <path opacity="0.125" fill="#737373" d="M417.6,409.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C417.3,409.7,417.6,409.5,417.6,409.2z"/>
            <path opacity="0.125" fill="#737373" d="M403.1,409.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C402.8,409.7,403.1,409.5,403.1,409.2z"/>
            <path opacity="0.125" fill="#737373" d="M486.6,409.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C486.4,409.7,486.6,409.5,486.6,409.2z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,409.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,409.7,472.1,409.5,472.1,409.2z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,409.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,409.7,457.7,409.5,457.7,409.2z"/>
            <path opacity="0.125" fill="#737373" d="M432.1,457.8V446c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C431.8,458.3,432.1,458.1,432.1,457.8z"/>
            <path opacity="0.125" fill="#737373" d="M417.6,457.8V446c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C417.3,458.3,417.6,458.1,417.6,457.8z"/>
            <path opacity="0.125" fill="#737373" d="M403.1,457.8V446c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C402.8,458.3,403.1,458.1,403.1,457.8z"/>
            <path opacity="0.125" fill="#737373" d="M486.6,457.8V446c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C486.4,458.3,486.6,458.1,486.6,457.8z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,457.8V446c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,458.3,472.1,458.1,472.1,457.8z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,457.8V446c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,458.3,457.7,458.1,457.7,457.8z"/>
            <path opacity="0.125" fill="#737373" d="M432.1,490.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C431.8,490.7,432.1,490.5,432.1,490.2z"/>
            <path opacity="0.125" fill="#737373" d="M417.6,490.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C417.3,490.7,417.6,490.5,417.6,490.2z"/>
            <path opacity="0.125" fill="#737373" d="M403.1,490.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C402.8,490.7,403.1,490.5,403.1,490.2z"/>
            <path opacity="0.125" fill="#737373" d="M486.6,490.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C486.4,490.7,486.6,490.5,486.6,490.2z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,490.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,490.7,472.1,490.5,472.1,490.2z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,490.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,490.7,457.7,490.5,457.7,490.2z"/>
            <path opacity="0.125" fill="#737373" d="M432.1,474v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V474c0,0.3,0.2,0.5,0.5,0.5h11.8C431.8,474.5,432.1,474.3,432.1,474z"/>
            <path opacity="0.125" fill="#737373" d="M417.6,474v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V474c0,0.3,0.2,0.5,0.5,0.5h11.8C417.3,474.5,417.6,474.3,417.6,474z"/>
            <path opacity="0.125" fill="#737373" d="M403.1,474v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V474c0,0.3,0.2,0.5,0.5,0.5h11.8C402.8,474.5,403.1,474.3,403.1,474z"/>
            <path opacity="0.125" fill="#737373" d="M486.6,474v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V474c0,0.3,0.2,0.5,0.5,0.5h11.8C486.4,474.5,486.6,474.3,486.6,474z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,474v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V474c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,474.5,472.1,474.3,472.1,474z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,474v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V474c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,474.5,457.7,474.3,457.7,474z"/>
            <path opacity="0.125" fill="#737373" d="M432.1,506.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C431.8,506.9,432.1,506.7,432.1,506.4z"/>
            <path opacity="0.125" fill="#737373" d="M417.6,506.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C417.3,506.9,417.6,506.7,417.6,506.4z"/>
            <path opacity="0.125" fill="#737373" d="M403.1,506.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C402.8,506.9,403.1,506.7,403.1,506.4z"/>
            <path opacity="0.125" fill="#737373" d="M486.6,506.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C486.4,506.9,486.6,506.7,486.6,506.4z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,506.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,506.9,472.1,506.7,472.1,506.4z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,506.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,506.9,457.7,506.7,457.7,506.4z"/>
            <path opacity="0.125" fill="#737373" d="M432.1,522.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C431.8,523.1,432.1,522.9,432.1,522.6z"/>
            <path opacity="0.125" fill="#737373" d="M417.6,522.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C417.3,523.1,417.6,522.9,417.6,522.6z"/>
            <path opacity="0.125" fill="#737373" d="M403.1,522.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C402.8,523.1,403.1,522.9,403.1,522.6z"/>
            <path opacity="0.125" fill="#737373" d="M486.6,522.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C486.4,523.1,486.6,522.9,486.6,522.6z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,522.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,523.1,472.1,522.9,472.1,522.6z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,522.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,523.1,457.7,522.9,457.7,522.6z"/>
            <path opacity="0.125" fill="#737373" d="M432.1,538.8v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C431.8,539.3,432.1,539.1,432.1,538.8z"/>
            <path opacity="0.125" fill="#737373" d="M417.6,538.8v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C417.3,539.3,417.6,539.1,417.6,538.8z"/>
            <path opacity="0.125" fill="#737373" d="M403.1,538.8v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C402.8,539.3,403.1,539.1,403.1,538.8z"/>
            <path opacity="0.125" fill="#737373" d="M486.6,538.8v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C486.4,539.3,486.6,539.1,486.6,538.8z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,538.8v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,539.3,472.1,539.1,472.1,538.8z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,538.8v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,539.3,457.7,539.1,457.7,538.8z"/>
            <path opacity="0.125" fill="#737373" d="M486.6,555v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V555c0,0.3,0.2,0.5,0.5,0.5h11.8C486.4,555.5,486.6,555.3,486.6,555z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,555v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V555c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,555.5,472.1,555.3,472.1,555z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,555v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V555c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,555.5,457.7,555.3,457.7,555z"/>
            <path opacity="0.125" fill="#737373" d="M486.6,571.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C486.4,571.8,486.6,571.5,486.6,571.2z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,571.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,571.8,472.1,571.5,472.1,571.2z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,571.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,571.8,457.7,571.5,457.7,571.2z"/>
            <path opacity="0.125" fill="#737373" d="M432.1,682.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C431.8,682.6,432.1,682.3,432.1,682.1z"/>
            <path opacity="0.125" fill="#737373" d="M417.6,682.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C417.3,682.6,417.6,682.3,417.6,682.1z"/>
            <path opacity="0.125" fill="#737373" d="M403.1,682.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C402.8,682.6,403.1,682.3,403.1,682.1z"/>
            <path opacity="0.125" fill="#737373" d="M486.6,682.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C486.4,682.6,486.6,682.3,486.6,682.1z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,682.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,682.6,472.1,682.3,472.1,682.1z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,682.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,682.6,457.7,682.3,457.7,682.1z"/>
            <path opacity="0.125" fill="#737373" d="M432.1,665.8v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C431.8,666.4,432.1,666.1,432.1,665.8z"/>
            <path opacity="0.125" fill="#737373" d="M417.6,665.8v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C417.3,666.4,417.6,666.1,417.6,665.8z"/>
            <path opacity="0.125" fill="#737373" d="M403.1,665.8v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C402.8,666.4,403.1,666.1,403.1,665.8z"/>
            <path opacity="0.125" fill="#737373" d="M486.6,665.8v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C486.4,666.4,486.6,666.1,486.6,665.8z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,665.8v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,666.4,472.1,666.1,472.1,665.8z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,665.8v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,666.4,457.7,666.1,457.7,665.8z"/>
            <path opacity="0.125" fill="#737373" d="M432.1,649.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C431.8,650.2,432.1,649.9,432.1,649.6z"/>
            <path opacity="0.125" fill="#737373" d="M417.6,649.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C417.3,650.2,417.6,649.9,417.6,649.6z"/>
            <path opacity="0.125" fill="#737373" d="M403.1,649.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C402.8,650.2,403.1,649.9,403.1,649.6z"/>
            <path opacity="0.125" fill="#737373" d="M486.6,649.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C486.4,650.2,486.6,649.9,486.6,649.6z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,649.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,650.2,472.1,649.9,472.1,649.6z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,649.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,650.2,457.7,649.9,457.7,649.6z"/>
            <path opacity="0.125" fill="#737373" d="M432.1,633.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C431.8,634,432.1,633.7,432.1,633.4z"/>
            <path opacity="0.125" fill="#737373" d="M417.6,633.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C417.3,634,417.6,633.7,417.6,633.4z"/>
            <path opacity="0.125" fill="#737373" d="M403.1,633.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C402.8,634,403.1,633.7,403.1,633.4z"/>
            <path opacity="0.125" fill="#737373" d="M486.6,633.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C486.4,634,486.6,633.7,486.6,633.4z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,633.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,634,472.1,633.7,472.1,633.4z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,633.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,634,457.7,633.7,457.7,633.4z"/>
            <path opacity="0.125" fill="#737373" d="M432.1,617.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C431.8,617.8,432.1,617.5,432.1,617.2z"/>
            <path opacity="0.125" fill="#737373" d="M417.6,617.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C417.3,617.8,417.6,617.5,417.6,617.2z"/>
            <path opacity="0.125" fill="#737373" d="M403.1,617.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C402.8,617.8,403.1,617.5,403.1,617.2z"/>
            <path opacity="0.125" fill="#737373" d="M486.6,617.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C486.4,617.8,486.6,617.5,486.6,617.2z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,617.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,617.8,472.1,617.5,472.1,617.2z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,617.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,617.8,457.7,617.5,457.7,617.2z"/>
            <path opacity="0.125" fill="#737373" d="M432.1,601v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V601c0,0.3,0.2,0.5,0.5,0.5h11.8C431.8,601.5,432.1,601.3,432.1,601z"/>
            <path opacity="0.125" fill="#737373" d="M417.6,601v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V601c0,0.3,0.2,0.5,0.5,0.5h11.8C417.3,601.5,417.6,601.3,417.6,601z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,601v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V601c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,601.5,472.1,601.3,472.1,601z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,601v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V601c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,601.5,457.7,601.3,457.7,601z"/>
            <path opacity="0.125" fill="#737373" d="M432.1,698.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C431.8,698.8,432.1,698.5,432.1,698.3z"/>
            <path opacity="0.125" fill="#737373" d="M417.6,698.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C417.3,698.8,417.6,698.5,417.6,698.3z"/>
            <path opacity="0.125" fill="#737373" d="M403.1,698.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C402.8,698.8,403.1,698.5,403.1,698.3z"/>
            <path opacity="0.125" fill="#737373" d="M486.6,698.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C486.4,698.8,486.6,698.5,486.6,698.3z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,698.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,698.8,472.1,698.5,472.1,698.3z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,698.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,698.8,457.7,698.5,457.7,698.3z"/>
            <path opacity="0.125" fill="#737373" d="M432.1,714.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C431.8,715,432.1,714.7,432.1,714.5z"/>
            <path opacity="0.125" fill="#737373" d="M417.6,714.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C417.3,715,417.6,714.7,417.6,714.5z"/>
            <path opacity="0.125" fill="#737373" d="M403.1,714.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C402.8,715,403.1,714.7,403.1,714.5z"/>
            <path opacity="0.125" fill="#737373" d="M486.6,714.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C486.4,715,486.6,714.7,486.6,714.5z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,714.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,715,472.1,714.7,472.1,714.5z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,714.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,715,457.7,714.7,457.7,714.5z"/>
            <path opacity="0.125" fill="#737373" d="M432.1,730.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C431.8,731.2,432.1,731,432.1,730.7z"/>
            <path opacity="0.125" fill="#737373" d="M417.6,730.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C417.3,731.2,417.6,731,417.6,730.7z"/>
            <path opacity="0.125" fill="#737373" d="M403.1,730.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C402.8,731.2,403.1,731,403.1,730.7z"/>
            <path opacity="0.125" fill="#737373" d="M486.6,730.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C486.4,731.2,486.6,731,486.6,730.7z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,730.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,731.2,472.1,731,472.1,730.7z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,730.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,731.2,457.7,731,457.7,730.7z"/>
            <path opacity="0.125" fill="#737373" d="M432.1,746.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C431.8,747.4,432.1,747.2,432.1,746.9z"/>
            <path opacity="0.125" fill="#737373" d="M417.6,746.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C417.3,747.4,417.6,747.2,417.6,746.9z"/>
            <path opacity="0.125" fill="#737373" d="M403.1,746.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C402.8,747.4,403.1,747.2,403.1,746.9z"/>
            <path opacity="0.125" fill="#737373" d="M486.6,746.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C486.4,747.4,486.6,747.2,486.6,746.9z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,746.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,747.4,472.1,747.2,472.1,746.9z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,746.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,747.4,457.7,747.2,457.7,746.9z"/>
            <path opacity="0.125" fill="#737373" d="M432.1,763.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C431.8,763.6,432.1,763.4,432.1,763.1z"/>
            <path opacity="0.125" fill="#737373" d="M417.6,763.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C417.3,763.6,417.6,763.4,417.6,763.1z"/>
            <path opacity="0.125" fill="#737373" d="M403.1,763.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C402.8,763.6,403.1,763.4,403.1,763.1z"/>
            <path opacity="0.125" fill="#737373" d="M486.6,763.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C486.4,763.6,486.6,763.4,486.6,763.1z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,763.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,763.6,472.1,763.4,472.1,763.1z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,763.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,763.6,457.7,763.4,457.7,763.1z"/>
            <path opacity="0.125" fill="#737373" d="M432.1,779.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C431.8,779.8,432.1,779.6,432.1,779.3z"/>
            <path opacity="0.125" fill="#737373" d="M417.6,779.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C417.3,779.8,417.6,779.6,417.6,779.3z"/>
            <path opacity="0.125" fill="#737373" d="M403.1,779.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C402.8,779.8,403.1,779.6,403.1,779.3z"/>
            <path opacity="0.125" fill="#737373" d="M486.6,779.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C486.4,779.8,486.6,779.6,486.6,779.3z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,779.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,779.8,472.1,779.6,472.1,779.3z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,779.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,779.8,457.7,779.6,457.7,779.3z"/>
            <path opacity="0.125" fill="#737373" d="M432.1,795.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C431.8,796,432.1,795.8,432.1,795.5z"/>
            <path opacity="0.125" fill="#737373" d="M417.6,795.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C417.3,796,417.6,795.8,417.6,795.5z"/>
            <path opacity="0.125" fill="#737373" d="M403.1,795.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C402.8,796,403.1,795.8,403.1,795.5z"/>
            <path opacity="0.125" fill="#737373" d="M486.6,795.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C486.4,796,486.6,795.8,486.6,795.5z"/>
            <path opacity="0.125" fill="#737373" d="M472.1,795.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C471.9,796,472.1,795.8,472.1,795.5z"/>
            <path opacity="0.125" fill="#737373" d="M457.7,795.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C457.4,796,457.7,795.8,457.7,795.5z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('lav4all', 'front-section')">
            <path class="editable-content__background" fill="#C4C4C4" d="M486.8,124.1v18.2c0,1.1-0.9,2-2,2H447c-1.1,0-2-0.9-2-2v-18.2c0-1.1,0.9-2,2-2h37.8 C485.9,122.1,486.8,123,486.8,124.1z"/>
            <path class="editable-content__icon" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M461.6,126.4c0.7,0,1.3,0.4,1.5,1c0.3,0.6,0.1,1.3-0.4,1.8 c-0.5,0.5-1.2,0.6-1.8,0.4c-0.6-0.2-1-0.8-1-1.5C460,127.1,460.7,126.4,461.6,126.4z M469.4,126.4c0.9,0,1.6,0.7,1.6,1.6 c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6C467.7,127.1,468.4,126.4,469.4,126.4z M470.7,137.7c-0.1,0.7-0.2,1.5-0.3,2.2h-2.1 c-0.1-0.7-0.2-1.5-0.3-2.2h-2.4l1.8-4.3c-0.2-0.9-0.4-1.7-0.5-2.6h4.9c-0.2,0.9-0.4,1.7-0.5,2.6l1.8,4.3H470.7z M463.6,135.4 L463,140h-2.8l-0.6-4.6h-1c0.2-1.5,0.4-3.1,0.6-4.6h4.8c0.2,1.5,0.4,3.1,0.6,4.6H463.6z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('lav4all', 'middle-section')">
            <path class="editable-content__background" fill="#C4C4C4" d="M432.2,554.8V573c0,1.1-0.9,2-2,2h-37.8c-1.1,0-2-0.9-2-2v-18.2c0-1.1,0.9-2,2-2h37.8 C431.3,552.8,432.2,553.7,432.2,554.8z"/>
            <path class="editable-content__icon" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M407,557.1c0.7,0,1.3,0.4,1.5,1c0.3,0.6,0.1,1.3-0.3,1.8 c-0.5,0.5-1.2,0.6-1.8,0.4c-0.6-0.2-1-0.8-1-1.5C405.4,557.8,406.1,557.1,407,557.1z M414.8,557.1c0.9,0,1.6,0.7,1.6,1.6 s-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6S413.9,557.1,414.8,557.1z M416.1,568.4c-0.1,0.7-0.2,1.5-0.3,2.2h-2.1 c-0.1-0.7-0.2-1.5-0.3-2.2H411l1.8-4.3c-0.2-0.9-0.4-1.7-0.5-2.6h4.9c-0.2,0.9-0.4,1.7-0.5,2.6l1.8,4.3H416.1z M409.1,566.1 l-0.6,4.6h-2.8l-0.6-4.6h-1c0.2-1.5,0.4-3.1,0.6-4.6h4.8c0.2,1.5,0.4,3.1,0.6,4.6H409.1z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('lav4all', 'aft-section-left')">
            <path class="editable-content__background" fill="#C4C4C4" d="M432.2,800.4v18.2c0,1.1-0.9,2-2,2h-37.8c-1.1,0-2-0.9-2-2v-18.2c0-1.1,0.9-2,2-2h37.8 C431.3,798.4,432.2,799.3,432.2,800.4z"/>
            <path class="editable-content__icon" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M407,802.7c0.7,0,1.3,0.4,1.5,1c0.3,0.6,0.1,1.3-0.3,1.8 c-0.5,0.5-1.2,0.6-1.8,0.4c-0.6-0.2-1-0.8-1-1.5C405.4,803.4,406.1,802.7,407,802.7z M414.8,802.7c0.9,0,1.6,0.7,1.6,1.6 s-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6S413.9,802.7,414.8,802.7z M416.1,814.1c-0.1,0.7-0.2,1.5-0.3,2.2h-2.1 c-0.1-0.7-0.2-1.5-0.3-2.2H411l1.8-4.3c-0.2-0.9-0.4-1.7-0.5-2.6h4.9c-0.2,0.9-0.4,1.7-0.5,2.6l1.8,4.3H416.1z M409.1,811.7 l-0.6,4.6h-2.8l-0.6-4.6h-1c0.2-1.5,0.4-3.1,0.6-4.6h4.8c0.2,1.5,0.4,3.1,0.6,4.6H409.1z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('lav4all', 'aft-section-right')">
            <path class="editable-content__background" fill="#C4C4C4" d="M486.8,800.4v18.2c0,1.1-0.9,2-2,2H447c-1.1,0-2-0.9-2-2v-18.2c0-1.1,0.9-2,2-2h37.8 C485.9,798.4,486.8,799.3,486.8,800.4z"/>
            <path class="editable-content__icon" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M461.6,802.7c0.7,0,1.3,0.4,1.5,1c0.3,0.6,0.1,1.3-0.4,1.8 c-0.5,0.5-1.2,0.6-1.8,0.4c-0.6-0.2-1-0.8-1-1.5C460,803.4,460.7,802.7,461.6,802.7z M469.4,802.7c0.9,0,1.6,0.7,1.6,1.6 s-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6S468.4,802.7,469.4,802.7z M470.7,814.1c-0.1,0.7-0.2,1.5-0.3,2.2h-2.1 c-0.1-0.7-0.2-1.5-0.3-2.2h-2.4l1.8-4.3c-0.2-0.9-0.4-1.7-0.5-2.6h4.9c-0.2,0.9-0.4,1.7-0.5,2.6l1.8,4.3H470.7z M463.6,811.7 l-0.6,4.6h-2.8l-0.6-4.6h-1c0.2-1.5,0.4-3.1,0.6-4.6h4.8c0.2,1.5,0.4,3.1,0.6,4.6H463.6z"/>
        </g>
        <path fill="#C4C4C4" d="M432.2,124.1v18.2c0,1.1-0.9,2-2,2h-37.8c-1.1,0-2-0.9-2-2v-18.2c0-1.1,0.9-2,2-2h37.8 C431.3,122.1,432.2,123,432.2,124.1z"/>
        <path fill="#FFFFFF" d="M403.1,136.7h15.5c0,0.9-0.7,1.6-1.6,1.6h-12.4C403.7,138.3,403.1,137.6,403.1,136.7L403.1,136.7z"/>
        <path fill="#FFFFFF" d="M406.1,128.3h9.2v6.1c0,0.8-0.7,1.5-1.5,1.5h-6c-0.4,0-0.8-0.2-1-0.4c-0.3-0.3-0.4-0.7-0.4-1v-0.5 c-0.8,0-1.5-0.2-2.1-0.8c-0.6-0.5-0.9-1.2-0.9-2C403.4,129.6,404.6,128.3,406.1,128.3z M406.4,132.7v-3.2h-0.3 c-0.9,0-1.6,0.7-1.6,1.6c0,0.5,0.2,1,0.6,1.3S405.9,132.8,406.4,132.7z"/>
        <g class="editable-content pulse-item" :class="contentClasses('partition', 'business')">
            <path class="editable-content__background" fill="#C4C4C4" d="M432.2,170.4v-2c0-1.1-0.9-2-2-2h-37.8c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h37.8 C431.3,172.4,432.2,171.5,432.2,170.4z"/>
            <path class="editable-content__background" fill="#C4C4C4" d="M486.8,170.4v-2c0-1.1-0.9-2-2-2H447c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h37.8 C485.9,172.4,486.8,171.5,486.8,170.4z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('partition', 'economy-middle')">
            <path class="editable-content__background" fill="#C4C4C4" d="M432.2,549.1v-4.5c0-1.1-0.9-2-2-2h-37.8c-1.1,0-2,0.9-2,2v4.5c0,1.1,0.9,2,2,2h37.8 C431.3,551.1,432.2,550.2,432.2,549.1z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('moveableDivider', 'premium-economy')">
            <path class="editable-content__background" fill="transparent" d="M432.5,366.2L432.5,366.2c0-0.7-0.6-1.3-1.3-1.3H392c-0.7,0-1.3,0.6-1.3,1.3l0,0c0,0.7,0.6,1.3,1.3,1.3   h39.2C431.9,367.5,432.5,366.9,432.5,366.2z"/>
            <path class="editable-content__background" fill="transparent" d="M486.6,366.2L486.6,366.2c0-0.7-0.6-1.3-1.3-1.3h-39.2c-0.7,0-1.3,0.6-1.3,1.3l0,0c0,0.7,0.6,1.3,1.3,1.3   h39.2C486.1,367.5,486.6,366.9,486.6,366.2z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('moveableDivider', 'economy')">
            <path class="editable-content__background" fill="transparent" d="M432.2,459.9L432.2,459.9c0-0.7-0.6-1.3-1.3-1.3h-39.2c-0.7,0-1.3,0.6-1.3,1.3l0,0c0,0.7,0.6,1.3,1.3,1.3   h39.2C431.6,461.2,432.2,460.6,432.2,459.9z"/>
            <path class="editable-content__background" fill="transparent" d="M486.3,459.9L486.3,459.9c0-0.7-0.6-1.3-1.3-1.3h-39.2c-0.7,0-1.3,0.6-1.3,1.3l0,0c0,0.7,0.6,1.3,1.3,1.3   H485C485.8,461.2,486.3,460.6,486.3,459.9z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('centerCeilingStowage', 'front-section')">
            <path class="editable-content__background" fill="transparent" d="M434.9,251h6.8c0,0,0.1-0.1,0.1-0.2v-41.7c0-0.1,0-0.2-0.1-0.2h-6.8c0,0-0.1,0.1-0.1,0.2v41.7  C434.8,250.9,434.9,251,434.9,251z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('centerCeilingStowage', 'middle-section')">
            <path class="editable-content__background" fill="transparent" d="M435.1,515.7h6.8c0,0,0.1-0.1,0.1-0.2v-41.7c0-0.1,0-0.2-0.1-0.2h-6.8c0,0-0.1,0.1-0.1,0.2v41.7  C435,515.7,435.1,515.7,435.1,515.7z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('centerCeilingStowage', 'aft-section')">
            <path class="editable-content__background" fill="transparent" d="M434.9,769.8h6.8c0,0,0.1-0.1,0.1-0.2V728c0-0.1,0-0.2-0.1-0.2h-6.8c0,0-0.1,0.1-0.1,0.2v41.7  C434.8,769.8,434.8,769.8,434.9,769.8z"/>
        </g>
    </svg>
</template>

<script>
import { defineComponent } from 'vue'
import { planeContentProps, usePlaneContentClass } from '@/composables/plane-content'

export default defineComponent({
  props: {
    ...planeContentProps()
  },
  setup (props) {
    const { contentClasses } = usePlaneContentClass(props)

    return { contentClasses }
  }
})
</script>
