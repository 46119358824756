<template>
    <div>
        <div class="multi-select" :class="[`multi-select--${styling}`, { 'multi-select--uneven' : options.length % 2 !== 0 }]">
            <div class="multi-select__option" :class="{'is-selected': selectedValues.includes(option.slug)}" v-for="option in options" :key="option.slug" @click="optionClickHandler(option.slug)">
                <div class="multi-select__option__color" v-if="option.colorCode" :style="`background-color: ${option.colorCode}`"></div>
                <div class="multi-select__option__color" v-if="option.colorImage">
                    <img :src="option.colorImage" />
                </div>
                <div class="multi-select__option__content">
                    <div class="multi-select__option__title h4">{{ option.label }}</div>
                    <div v-if="option.description" class="multi-select__option__text copy-small">{{ option.description }}</div>
                </div>
                <div class="multi-select__option__icon" v-if="styling !== 'color'">
                    <icon :size="16">
                        <icon-checkmark />
                    </icon>
                </div>
            </div>
        </div>
        <div class="multi-select__image" v-if="singleActiveOption && singleActiveOption.detailImage">
            <img :src="singleActiveOption.detailImage" />
        </div>
    </div>
</template>

<script>
import Icon from '@/components/Icon.vue'
import IconCheckmark from '@/components/icons/IconCheckmark.vue'

export default {
  name: 'MultiSelect',
  components: {
    Icon,
    IconCheckmark
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    initialValues: {
      type: Array,
      default: () => []
    },
    styling: {
      type: String,
      default: 'default'
    },
    singleSelect: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedValues: []
    }
  },
  computed: {
    singleActiveOption () {
      if (this.singleSelect) {
        return this.options.find(option => option.slug === this.selectedValues[0])
      }
      return false
    }
  },
  watch: {
    initialValues: {
      handler: function (newValue) {
        if (newValue) {
          this.selectedValues = [...newValue]
        }
      },
      deep: true
    }
  },
  mounted () {
    if (this.initialValues) {
      this.selectedValues = [...this.initialValues]
    }
  },
  methods: {
    optionClickHandler (slug) {
      if (this.singleSelect) {
        this.switchOption(slug)
      } else {
        this.toggleOption(slug)
      }
    },
    toggleOption (slug) {
      if (this.selectedValues.includes(slug)) {
        this.selectedValues = this.selectedValues.filter(value => value !== slug)
      } else {
        this.selectedValues.push(slug)
      }

      this.$emit('selectionChanged', [...this.selectedValues])
    },
    switchOption (slug) {
      if (this.selectedValues.includes(slug)) {
        this.selectedValues = []
      } else {
        this.selectedValues = [slug]
      }
      this.$emit('selectionChanged', [...this.selectedValues])
    }
  }
}
</script>

<style lang="scss">
    .multi-select {
        $block: &;
        display: grid;
        gap: 8px;

        &--color {
            grid-template-columns: 1fr 1fr;

            &#{$block}--uneven {
                #{$block}__option {
                    &:last-child {
                        grid-column: span 2;
                        justify-content: center;
                    }
                }
            }
        }

        &__option {
            display: flex;
            align-items: center;
            gap: 16px;
            border-radius: $border-radius-s;
            padding: 16px;
            border: 2px solid $color-grey-medium;
            transition: $hover-transition;
            cursor: pointer;

            &.is-selected {
                border-color: $color-primary;
            }

            .no-touch &:hover {
                border-color: $color-primary;
            }

            &__text {
                margin-top: 4px;
            }

            &__color {
                width: 40px;
                height: 40px;
                border-radius: 100%;
                overflow: hidden;
                flex-shrink: 0;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                height: 32px;
                border-radius: 100%;
                background: $color-primary;
                color: $color-white;
                margin-left: auto;
                flex-shrink: 0;
                opacity: 0;
                transition: $hover-transition;

                .is-selected & {
                    opacity: 1;
                }
            }
        }

        &__image {
            position: relative;
            height: 220px;
            margin-top: 45px;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
</style>
