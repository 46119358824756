const AIRCRAFT_TYPES = [
  {
    label: 'Single Aisle',
    slug: 'single-aisle',
    image: 'airplane-1-new.png',
    layouts: [
      {
        label: 'High Density',
        slug: 'high-density',
        component: 'SingleAisleHighDensity',
        description: 'Every seat counts - we offer dedicated solutions to maximise your yields and the flexibility of your fleet.',
        availableFeatures: {
          lav4all: {
            positions: [
              {
                label: 'Front Section',
                slug: 'front-section'
                // description: 'Lorem ipsum dolor sit amet, consectetur adipiscing.'
              },
              {
                label: 'Aft Section Left',
                slug: 'aft-section-left'
                // description: 'Lorem ipsum dolor sit amet, consectetur adipiscing.'
              },
              {
                label: 'Aft Section Right',
                slug: 'aft-section-right'
                // description: 'Lorem ipsum dolor sit amet, consectetur adipiscing.'
              }
            ]
          },
          partition: {
            positions: [
              {
                label: 'Economy',
                slug: 'economy'
                // description: 'Partition Lorem ipsum dolor sit amet, consectetur adipiscing.'
              }
            ]
          },
          moveableDivider: {
            positions: [
              {
                label: 'Economy',
                slug: 'economy'
                // description: 'Partition Lorem ipsum dolor sit amet, consectetur adipiscing.'
              }
            ]
          },
          centerCeilingStowage: {
            positions: [
              {
                label: 'Front Section',
                slug: 'front-section'
                // description: 'Center Ceiling Stowage Lorem ipsum dolor sit amet, consectetur adipiscing.'
              },
              {
                label: 'Middle Section',
                slug: 'middle-section'
                // description: 'Center Ceiling Stowage Lorem ipsum dolor sit amet, consectetur adipiscing.'
              },
              {
                label: 'Aft Section',
                slug: 'aft-section'
                // description: 'Center Ceiling Stowage Lorem ipsum dolor sit amet, consectetur adipiscing.'
              }
            ]
          },
          sidewallDecorCover: {
            classes: [{
              label: 'Economy',
              slug: 'economy'
            }],
            colors: ['chocolateleather', 'orientalwood', 'oceanwood', 'foggywood', 'rawsperryleather', 'rosevelvet', 'custom']
          }
        }
      },
      {
        label: '2-Class Configuration',
        slug: '2-class',
        component: 'SingleAisle2Class',
        description: 'We ensure that all your passengers\' comfort and safety requirements are met in Business and Economy Class.',
        availableFeatures: {
          lav4all: {
            positions: [
              {
                label: 'Front Section',
                slug: 'front-section'
                // description: 'Lorem ipsum dolor sit amet, consectetur adipiscing.'
              },
              {
                label: 'Aft Section Left',
                slug: 'aft-section-left'
                // description: 'Lorem ipsum dolor sit amet, consectetur adipiscing.'
              },
              {
                label: 'Aft Section Right',
                slug: 'aft-section-right'
                // description: 'Lorem ipsum dolor sit amet, consectetur adipiscing.'
              }
            ]
          },
          partition: {
            positions: [
              {
                label: 'Economy',
                slug: 'economy'
                // description: 'Partition Lorem ipsum dolor sit amet, consectetur adipiscing.'
              },
              {
                label: 'Business',
                slug: 'business'
                // description: 'Partition Lorem ipsum dolor sit amet, consectetur adipiscing.'
              }
            ]
          },
          moveableDivider: {
            positions: [
              {
                label: 'Economy',
                slug: 'economy'
                // description: 'Partition Lorem ipsum dolor sit amet, consectetur adipiscing.'
              }
            ]
          },
          centerCeilingStowage: {
            positions: [
              {
                label: 'Front Section',
                slug: 'front-section'
                // description: 'Center Ceiling Stowage Lorem ipsum dolor sit amet, consectetur adipiscing.'
              },
              {
                label: 'Middle Section',
                slug: 'middle-section'
                // description: 'Center Ceiling Stowage Lorem ipsum dolor sit amet, consectetur adipiscing.'
              },
              {
                label: 'Aft Section',
                slug: 'aft-section'
                // description: 'Center Ceiling Stowage Lorem ipsum dolor sit amet, consectetur adipiscing.'
              }
            ]
          },
          sidewallDecorCover: {
            classes: [
              {
                label: 'Business',
                slug: 'business'
              },
              {
                label: 'Economy',
                slug: 'economy'
              }
            ],
            colors: ['chocolateleather', 'orientalwood', 'oceanwood', 'foggywood', 'rawsperryleather', 'rosevelvet', 'custom']
          }
        }
      },
      {
        label: '3-Class Configuration',
        slug: '3-class',
        component: 'SingleAisle3Class',
        description: 'FACC helps you differentiate your Business Class, Premium Economy Class and Economy Class through our unique cabin designs.',
        availableFeatures: {
          lav4all: {
            positions: [
              {
                label: 'Front Section',
                slug: 'front-section'
                // description: 'Lorem ipsum dolor sit amet, consectetur adipiscing.'
              },
              {
                label: 'Middle Section',
                slug: 'middle-section'
                // description: 'Lorem ipsum dolor sit amet, consectetur adipiscing.'
              },
              {
                label: 'Aft Section Left',
                slug: 'aft-section-left'
                // description: 'Lorem ipsum dolor sit amet, consectetur adipiscing.'
              },
              {
                label: 'Aft Section Right',
                slug: 'aft-section-right'
                // description: 'Lorem ipsum dolor sit amet, consectetur adipiscing.'
              }
            ]
          },
          partition: {
            positions: [
              {
                label: 'Business Class',
                slug: 'business'
                // description: 'Partition Lorem ipsum dolor sit amet, consectetur adipiscing.'
              },
              {
                label: 'Premium Economy',
                slug: 'premium-economy'
                // description: 'Partition Lorem ipsum dolor sit amet, consectetur adipiscing.'
              },
              {
                label: 'Economy Front',
                slug: 'economy-front'
                // description: 'Partition Lorem ipsum dolor sit amet, consectetur adipiscing.'
              },
              {
                label: 'Economy Middle',
                slug: 'economy-middle'
                // description: 'Partition Lorem ipsum dolor sit amet, consectetur adipiscing.'
              }
            ]
          },
          moveableDivider: {
            positions: [
              {
                label: 'Premium Economy',
                slug: 'premium-economy'
                // description: 'Moveable Divider Lorem ipsum dolor sit amet, consectetur adipiscing.'
              },
              {
                label: 'Economy',
                slug: 'economy'
                // description: 'Moveable Divider Lorem ipsum dolor sit amet, consectetur adipiscing.'
              }
            ]
          },
          centerCeilingStowage: {
            positions: [
              {
                label: 'Front Section',
                slug: 'front-section'
                // description: 'Center Ceiling Stowage Lorem ipsum dolor sit amet, consectetur adipiscing.'
              },
              {
                label: 'Middle Section',
                slug: 'middle-section'
                // description: 'Center Ceiling Stowage Lorem ipsum dolor sit amet, consectetur adipiscing.'
              },
              {
                label: 'Aft Section',
                slug: 'aft-section'
                // description: 'Center Ceiling Stowage Lorem ipsum dolor sit amet, consectetur adipiscing.'
              }
            ]
          },
          sidewallDecorCover: {
            classes: [
              {
                label: 'Business',
                slug: 'business'
              },
              {
                label: 'PremiumEconomy',
                slug: 'premium-economy'
              },
              {
                label: 'Economy',
                slug: 'economy'
              }
            ],
            colors: ['chocolateleather', 'orientalwood', 'oceanwood', 'foggywood', 'rawsperryleather', 'rosevelvet', 'custom']
          }
        }
      }
    ]
  }
//   {
//     label: 'Wide body',
//     slug: 'wide-body',
//     image: 'airplane-1.png',
//     layouts: [
//       {
//         label: 'High Density',
//         slug: 'high-density',
//         description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et '
//       }
//     ]
//   }
]

export default AIRCRAFT_TYPES
