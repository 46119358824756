<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="873.9px" height="1118px" viewBox="0 0 873.9 1118" enable-background="new 0 0 873.9 1118" xml:space="preserve">
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M551.1,545.8 c0.2,11.7,1.3,12.7,3.2,17.5c0.1,0.2,0.3,0.3,0.5,0.3s0.4-0.1,0.5-0.3c1.9-4.8,3.2-5.8,3.4-17.5"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M638,555.4 c0.2,14.3,1.6,16.6,3.9,22.4c0.1,0.3,0.4,0.4,0.7,0.4c0.3,0,0.5-0.2,0.7-0.4c2.3-5.5,3.7-7,4.1-19.4"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M574.3,545.9 c0.1,6.5,0.9,7.1,2.2,9.7c0.1,0.1,0.2,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2c1.3-2.7,2.2-3.2,2.3-9.7H574.3z"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M323.6,545.8 c-0.2,11.7-1.3,12.7-3.2,17.5c-0.1,0.2-0.3,0.4-0.5,0.4s-0.4-0.1-0.5-0.4c-1.9-4.8-3.2-5.8-3.4-17.5"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M236.6,555.4 c-0.2,14.3-1.6,16.6-3.9,22.4c-0.1,0.3-0.4,0.4-0.7,0.4c-0.3,0-0.5-0.2-0.7-0.4c-2.2-5.5-3.7-7-4.1-19.4"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M300.3,545.9 c-0.1,6.5-0.9,7.1-2.2,9.7c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.3-0.1-0.4-0.2c-1.3-2.7-2.2-3.2-2.3-9.7H300.3z"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M488.1,390.4 c0,3.8,2.1,5.5,5.6,7.2l175.9,88l193.6,97c0,0,9.6,4.5,9.6,18.6c-0.1,14.1,0,34.2,0,34.2h-3l-0.2-14L742,585.3l-139.1-39.4H488.1"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M596.4,449 c8-5.2,9-30.7,9.5-46.8c0.2-8.7-0.2-17.4-1.3-26.1c-0.9-6.3-2-12.8-3.8-16.2h-42.1c0,0-6,8.7-5.8,30.4c0.1,12.9,1.2,25.8,3.3,38.5" />
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M386.5,390.4 c0,3.8-2.1,5.5-5.6,7.2l-175.9,88l-193.6,97c0,0-9.6,4.5-9.6,18.6c0,14.1,0,34.2,0,34.2h3l0.2-14l127.5-36.1l139.1-39.4h114.8"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M278.3,449 c-8-5.2-9-30.7-9.5-46.8c-0.2-8.7,0.2-17.4,1.3-26.1c0.8-6.3,2-12.8,3.8-16.2h42c0,0,6,8.7,5.8,30.4c-0.1,12.9-1.2,25.8-3.3,38.5"/>
        <path class="plane-body" opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M429.7,3.8 c-38.8,31-43.8,124.5-43.8,124.5c0,99.4,0,376.6,0,376.6s-0.7,343.7,0,343c1.3,94.7,51.3,226.1,51.3,226.1H437 c0,0,49.8-131.5,51.1-226.1c0.7,0.7,0-343,0-343s0-277.2,0-376.6c0,0-3.7-93.4-42.4-124.4C441,0.1,434.3,0.1,429.7,3.8z"/>
        <g class="editable-content" :class="contentClasses('sidewallDecorCover', 'business', 'selectedColors')">
            <rect class="editable-content__area" fill="transparent" x="385.9" y="128.3" width="102.5" height="165.4"/>
            <path class="editable-content__background pulse-item" fill="transparent" d="M386.5,290c-0.8,0-1.5-0.7-1.5-1.5v-132c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v132  C388,289.3,387.3,290,386.5,290z"/>
            <path class="editable-content__background pulse-item" fill="transparent" d="M487.4,290c-0.8,0-1.5-0.7-1.5-1.5v-132c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v132  C488.9,289.3,488.2,290,487.4,290z"/>
        </g>
        <g class="editable-content" :class="contentClasses('sidewallDecorCover', 'economy', 'selectedColors')">
            <rect class="editable-content__area" fill="transparent" x="385.9" y="293.5" width="102.5" height="551.6"/>
            <path class="editable-content__background pulse-item" fill="transparent" d="M386.5,831.6c-0.8,0-1.5-0.7-1.5-1.5V299.3c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v530.8  C388,830.9,387.3,831.6,386.5,831.6z"/>
            <path class="editable-content__background pulse-item" fill="transparent" d="M487.4,831.6c-0.8,0-1.5-0.7-1.5-1.5V299.3c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v530.8  C488.9,830.9,488.2,831.6,487.4,831.6z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('lav4all', 'aft-section-left')">
            <path class="editable-content__background" fill="#C4C4C4" d="M431,799.6v18.2c0,1.1-0.9,2-2,2h-37.8c-1.1,0-2-0.9-2-2v-18.2c0-1.1,0.9-2,2-2H429 C430.1,797.6,431,798.5,431,799.6z"/>
            <path class="editable-content__icon" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M405.8,801.8c0.7,0,1.3,0.4,1.5,1c0.3,0.6,0.1,1.3-0.3,1.8 c-0.5,0.5-1.2,0.6-1.8,0.4c-0.6-0.2-1-0.8-1-1.5C404.2,802.6,404.9,801.8,405.8,801.8z M413.6,801.8c0.9,0,1.6,0.7,1.6,1.6 c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6C411.9,802.5,412.7,801.8,413.6,801.8z M414.9,813.2c-0.1,0.7-0.2,1.5-0.3,2.2h-2.1 c-0.1-0.7-0.2-1.5-0.3-2.2h-2.4l1.8-4.3c-0.2-0.9-0.4-1.7-0.5-2.6h4.9c-0.2,0.9-0.4,1.7-0.5,2.6l1.8,4.3H414.9z M407.9,810.9 l-0.6,4.6h-2.8l-0.6-4.6h-1c0.2-1.5,0.4-3.1,0.6-4.6h4.8c0.2,1.5,0.4,3.1,0.6,4.6H407.9z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('lav4all', 'aft-section-right')">
            <path class="editable-content__background" fill="#C4C4C4" d="M485.6,799.6v18.2c0,1.1-0.9,2-2,2h-37.8c-1.1,0-2-0.9-2-2v-18.2c0-1.1,0.9-2,2-2h37.8 C484.7,797.6,485.6,798.5,485.6,799.6z"/>
            <path class="editable-content__icon" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M460.4,801.8c0.7,0,1.3,0.4,1.5,1c0.3,0.6,0.1,1.3-0.4,1.8 c-0.5,0.5-1.2,0.6-1.8,0.4c-0.6-0.2-1-0.8-1-1.5C458.8,802.6,459.5,801.8,460.4,801.8z M468.2,801.8c0.9,0,1.6,0.7,1.6,1.6 c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6C466.5,802.5,467.2,801.8,468.2,801.8z M469.5,813.2c-0.1,0.7-0.2,1.5-0.3,2.2h-2.1 c-0.1-0.7-0.2-1.5-0.3-2.2h-2.4l1.8-4.3c-0.2-0.9-0.4-1.7-0.5-2.6h4.9c-0.2,0.9-0.4,1.7-0.5,2.6l1.8,4.3H469.5z M462.4,810.9 l-0.6,4.6H459l-0.6-4.6h-1c0.2-1.5,0.4-3.1,0.6-4.6h4.8c0.2,1.5,0.4,3.1,0.6,4.6H462.4z"/>
        </g>
        <path fill="#C4C4C4" d="M485.6,838.8v13.1c0,1.1-0.9,2-2,2h-92.4c-1.1,0-2-0.9-2-2v-13.1c0-1.1,0.9-2,2-2h92.4 C484.7,836.8,485.6,837.7,485.6,838.8z"/>
        <path fill="#FFFFFF" d="M429.1,848.6h15.5c0,0.9-0.7,1.6-1.6,1.6h-12.4C429.7,850.2,429.1,849.5,429.1,848.6L429.1,848.6z"/>
        <path fill="#FFFFFF" d="M432.1,840.2h9.2v6.1c0,0.8-0.7,1.5-1.5,1.5h-6c-0.4,0-0.8-0.2-1-0.4c-0.3-0.3-0.4-0.7-0.4-1v-0.5 c-0.8,0-1.5-0.2-2.1-0.8c-0.6-0.5-0.9-1.2-0.9-2C429.4,841.5,430.6,840.3,432.1,840.2z M432.4,844.6v-3.2h-0.3 c-0.9,0-1.6,0.7-1.6,1.6c0,0.5,0.2,1,0.6,1.3C431.4,844.6,431.9,844.7,432.4,844.6z"/>
        <path opacity="0.6" fill="none" stroke="#33546C" stroke-width="0.75" d="M406.4,973.2l-175.7,121.1v23 l202.9-52.4h7.1l202.9,52.4v-23L467.8,973.2"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M769.2,593v-6.8l-166.8-52.9 H488.1v12.5h114.8L769.2,593z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M573.8,437.7v-55.4 c0-1.7,0.7-3.2,1.9-4.4c1.2-1.1,2.8-1.8,4.4-1.7c3.4-0.1,6.3,2.7,6.4,6.1v61.7"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M504.1,402.8l-7,11.3 l167.2,83.8l183,90.9l5.8-11.2l-183.6-92L504.1,402.8z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M764.2,584.6l5-16.2 l-117.5-38.3l-39.3-12.8l-6.9,17.1l40.7,12.9L764.2,584.6z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M488.1,533.4h111.3v-16.1 h-48.3v6.1h-63V533.4z"/>
        <path opacity="0.4" fill="none" stroke="#33546C" stroke-width="0.5" d="M437.2,1082.4 c-4.2-23.9-7-56.3-8.4-112.5c-1.6-64.6,3.6-99.2,8.4-116.3H437c4.8,17.1,10,51.7,8.4,116.3c-1.4,56.3-4.2,88.6-8.4,112.5"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.75" d="M556.1,365.9 c0.6-2.1,1.5-4,2.6-5.9h42.1c0.8,1.8,1.5,3.7,1.9,5.7l-46.6,0.1L556.1,365.9z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M105.4,593v-6.8l166.8-52.9 h114.4v12.5H271.8L105.4,593z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M300.8,437.7v-55.4 c-0.1-3.4-2.9-6.2-6.4-6.1c-1.7,0-3.3,0.6-4.4,1.7s-1.9,2.7-1.9,4.4v61.7"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M370.6,402.8l7,11.3 l-167.2,83.8l-183,90.9l-5.8-11.2l183.6-92L370.6,402.8z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M110.4,584.6l-5-16.2 L223,530.1l39.3-12.8l6.9,17.1l-40.7,12.9L110.4,584.6z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M386.5,533.4H275.3v-16.1h48.3 v6.1h62.9V533.4z"/>
        <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.75" d="M318.5,365.9 c-0.6-2.1-1.5-4-2.6-5.9h-42.1c-0.9,1.8-1.5,3.8-2,5.7L318.5,365.9L318.5,365.9z"/>
        <path opacity="0.4" fill="none" stroke="#33546C" stroke-width="0.75" d="M462.2,49L457,59.6l7.5,10.2 h9.3L462.2,49z"/>
        <path opacity="0.4" fill="none" stroke="#33546C" stroke-width="0.75" d="M475.1,73h-8.9l4.5,12.5h7 L475.1,73z"/>
        <path opacity="0.4" fill="none" stroke="#33546C" stroke-width="0.75" d="M411.9,49l5.3,10.5l-7.5,10.2 h-9.3L411.9,49z"/>
        <path opacity="0.4" fill="none" stroke="#33546C" stroke-width="0.75" d="M399.1,73h8.9l-4.4,12.5h-7 L399.1,73z"/>
        <g opacity="0.4">
            <path opacity="0.4" fill="none" stroke="#33546C" stroke-width="0.75" d="M454.1,56.3l4.8-11.2 l-20.5-13.2v14.7L454.1,56.3z"/>
            <path opacity="0.4" fill="none" stroke="#33546C" stroke-width="0.75" d="M435.6,32.1l-20.3,13.1 l4.8,11.2l15.5-9.6V32.1z"/>
        </g>
        <g opacity="0.2">
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M565.6,433.5l-6.2,11.7"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M608.7,455.2v14.6"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M669.6,485.6l-5.3,12.3"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M731,516.4l-5.8,11.7"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M792.5,547.2l-5.8,11.5"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M731,555.9l-5.8,16.3"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M691.5,543.1l-5.8,16.7"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M651.7,530.1l-5.5,17.1"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M551.1,523.4v10"/>
        </g>
        <g opacity="0.2">
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M309.1,433.5l6.2,11.7"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M265.9,455.2v14.6"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M205.1,485.6l5.3,12.3"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M143.7,516.4l5.8,11.7"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M82.1,547.2l5.8,11.5"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M143.7,555.9l5.8,16.3"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M183.1,543.1l5.8,16.7"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M223,530.1l5.5,17.1"/>
            <path opacity="0.2" fill="none" stroke="#737373" stroke-width="0.5" d="M323.6,523.4v10"/>
        </g>
        <path fill="#FFFFFF" d="M437.5,1035.9c0,0-2.8,2.8-3.6,23.5c-0.5,13.4,2.2,25.5,3.6,28.3h-0.2c1.4-2.8,4.1-14.9,3.6-28.3 c-0.8-20.7-3.6-23.5-3.6-23.5"/>
        <path fill="none" stroke="#33546C" stroke-width="0.75" d="M437.5,1035.9c0,0-2.8,2.8-3.6,23.5c-0.5,13.4,2.2,25.5,3.6,28.3h-0.2 c1.4-2.8,4.1-14.9,3.6-28.3c-0.8-20.7-3.6-23.5-3.6-23.5"/>
        <g>
            <g opacity="0.25">
                <path fill="#737373" d="M422.9,288.7l4-11.1c0.1-0.3,0-0.6-0.3-0.7l-11.1-4c-0.3-0.1-0.6,0-0.7,0.3l-4,11.1 c-0.1,0.3,0,0.6,0.3,0.7l11.1,4C422.5,289.1,422.8,288.9,422.9,288.7z"/>
                <path fill="#737373" d="M404.3,281.9l4-11.1c0.1-0.3,0-0.6-0.3-0.7l-11.1-4c-0.3-0.1-0.6,0-0.7,0.3l-4,11.1 c-0.1,0.3,0,0.6,0.3,0.7l11.1,4C403.9,282.3,404.2,282.2,404.3,281.9z"/>
                <path fill="#737373" d="M422.9,266.5l4-11.1c0.1-0.3,0-0.6-0.3-0.7l-11.1-4c-0.3-0.1-0.6,0-0.7,0.3l-4,11.1 c-0.1,0.3,0,0.6,0.3,0.7l11.1,4C422.5,266.9,422.8,266.8,422.9,266.5z"/>
                <path fill="#737373" d="M404.3,259.7l4-11.1c0.1-0.3,0-0.6-0.3-0.7l-11.1-4c-0.3-0.1-0.6,0-0.7,0.3l-4,11.1 c-0.1,0.3,0,0.6,0.3,0.7l11.1,4C403.9,260.1,404.2,260,404.3,259.7z"/>
                <path fill="#737373" d="M422.9,244.3l4-11.1c0.1-0.3,0-0.6-0.3-0.7l-11.1-4c-0.3-0.1-0.6,0-0.7,0.3l-4,11.1 c-0.1,0.3,0,0.6,0.3,0.7l11.1,4C422.5,244.7,422.8,244.6,422.9,244.3z"/>
                <path fill="#737373" d="M404.3,237.6l4-11.1c0.1-0.3,0-0.6-0.3-0.7l-11.1-4c-0.3-0.1-0.6,0-0.7,0.3l-4,11.1 c-0.1,0.3,0,0.6,0.3,0.7l11.1,4C403.9,238,404.2,237.8,404.3,237.6z"/>
                <path fill="#737373" d="M422.9,222.1l4-11.1c0.1-0.3,0-0.6-0.3-0.7l-11.1-4c-0.3-0.1-0.6,0-0.7,0.3l-4,11.1 c-0.1,0.3,0,0.6,0.3,0.7l11.1,4C422.5,222.5,422.8,222.4,422.9,222.1z"/>
                <path fill="#737373" d="M404.3,215.4l4-11.1c0.1-0.3,0-0.6-0.3-0.7l-11.1-4c-0.3-0.1-0.6,0-0.7,0.3l-4,11.1 c-0.1,0.3,0,0.6,0.3,0.7l11.1,4C403.9,215.8,404.2,215.7,404.3,215.4z"/>
                <path fill="#737373" d="M482.2,274.1l-4-11.1c-0.1-0.3-0.4-0.4-0.7-0.3l-11.1,4c-0.3,0.1-0.4,0.4-0.3,0.7l4,11.1 c0.1,0.3,0.4,0.4,0.7,0.3l11.1-4C482.2,274.7,482.3,274.4,482.2,274.1z"/>
                <path fill="#737373" d="M463.8,280.9l-4-11.1c-0.1-0.3-0.4-0.4-0.7-0.3l-11.1,4c-0.3,0.1-0.4,0.4-0.3,0.7l4,11.1 c0.1,0.3,0.4,0.4,0.7,0.3l11.1-4C463.7,281.4,463.9,281.1,463.8,280.9z"/>
                <path fill="#737373" d="M482.2,252l-4-11.1c-0.1-0.3-0.4-0.4-0.7-0.3l-11.1,4c-0.3,0.1-0.4,0.4-0.3,0.7l4,11.1 c0.1,0.3,0.4,0.4,0.7,0.3l11.1-4C482.2,252.5,482.3,252.2,482.2,252z"/>
                <path fill="#737373" d="M463.8,258.7l-4-11.1c-0.1-0.3-0.4-0.4-0.7-0.3l-11.1,4c-0.3,0.1-0.4,0.4-0.3,0.7l4,11.1 c0.1,0.3,0.4,0.4,0.7,0.3l11.1-4C463.7,259.2,463.9,258.9,463.8,258.7z"/>
                <path fill="#737373" d="M482.2,229.8l-4-11.1c-0.1-0.3-0.4-0.4-0.7-0.3l-11.1,4c-0.3,0.1-0.4,0.4-0.3,0.7l4,11.1 c0.1,0.3,0.4,0.4,0.7,0.3l11.1-4C482.2,230.4,482.3,230.1,482.2,229.8z"/>
                <path fill="#737373" d="M463.8,236.5l-4-11.1c-0.1-0.3-0.4-0.4-0.7-0.3l-11.1,4c-0.3,0.1-0.4,0.4-0.3,0.7l4,11.1 c0.1,0.3,0.4,0.4,0.7,0.3l11.1-4C463.7,237.1,463.9,236.8,463.8,236.5z"/>
                <path fill="#737373" d="M482.2,207.6l-4-11.1c-0.1-0.3-0.4-0.4-0.7-0.3l-11.1,4c-0.3,0.1-0.4,0.4-0.3,0.7l4,11.1 c0.1,0.3,0.4,0.4,0.7,0.3l11.1-4C482.2,208.2,482.3,207.9,482.2,207.6z"/>
                <path fill="#737373" d="M463.8,214.3l-4-11.1c-0.1-0.3-0.4-0.4-0.7-0.3l-11.1,4c-0.3,0.1-0.4,0.4-0.3,0.7l4,11.1 c0.1,0.3,0.4,0.4,0.7,0.3l11.1-4C463.7,214.9,463.9,214.6,463.8,214.3z"/>
                <path fill="#737373" d="M422.9,200l4-11.1c0.1-0.3,0-0.6-0.3-0.7l-11.1-4c-0.3-0.1-0.6,0-0.7,0.3l-4,11.1c-0.1,0.3,0,0.6,0.3,0.7 l11.1,4C422.5,200.4,422.8,200.2,422.9,200z"/>
                <path fill="#737373" d="M404.3,193.2l4-11.1c0.1-0.3,0-0.6-0.3-0.7l-11.1-4c-0.3-0.1-0.6,0-0.7,0.3l-4,11.1 c-0.1,0.3,0,0.6,0.3,0.7l11.1,4C403.9,193.6,404.2,193.5,404.3,193.2z"/>
            </g>
            <path opacity="0.125" fill="#737373" d="M430.9,468.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,469,430.9,468.7,430.9,468.5z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,468.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,469,416.4,468.7,416.4,468.5z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,468.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,469,401.9,468.7,401.9,468.5z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,468.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,469,485.4,468.7,485.4,468.5z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,468.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,469,470.9,468.7,470.9,468.5z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,468.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,469,456.4,468.7,456.4,468.5z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,452.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,452.8,430.9,452.5,430.9,452.3z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,452.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,452.8,416.4,452.5,416.4,452.3z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,452.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,452.8,401.9,452.5,401.9,452.3z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,452.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,452.8,485.4,452.5,485.4,452.3z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,452.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,452.8,470.9,452.5,470.9,452.3z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,452.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,452.8,456.4,452.5,456.4,452.3z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,436v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V436c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,436.6,430.9,436.3,430.9,436z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,436v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V436c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,436.6,416.4,436.3,416.4,436z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,436v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V436c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,436.6,401.9,436.3,401.9,436z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,436v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V436c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,436.6,485.4,436.3,485.4,436z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,436v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V436c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,436.6,470.9,436.3,470.9,436z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,436v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V436c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,436.6,456.4,436.3,456.4,436z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,419.8v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,420.4,430.9,420.1,430.9,419.8z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,419.8v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,420.4,416.4,420.1,416.4,419.8z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,419.8v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,420.4,401.9,420.1,401.9,419.8z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,419.8v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,420.4,485.4,420.1,485.4,419.8z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,419.8v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,420.4,470.9,420.1,470.9,419.8z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,419.8v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,420.4,456.4,420.1,456.4,419.8z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,403.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,404.2,430.9,403.9,430.9,403.6z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,403.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,404.2,416.4,403.9,416.4,403.6z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,403.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,404.2,401.9,403.9,401.9,403.6z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,403.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,404.2,485.4,403.9,485.4,403.6z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,403.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,404.2,470.9,403.9,470.9,403.6z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,403.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,404.2,456.4,403.9,456.4,403.6z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,387.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,387.9,430.9,387.7,430.9,387.4z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,387.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,387.9,416.4,387.7,416.4,387.4z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,387.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,387.9,401.9,387.7,401.9,387.4z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,387.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,387.9,485.4,387.7,485.4,387.4z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,387.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,387.9,470.9,387.7,470.9,387.4z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,387.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,387.9,456.4,387.7,456.4,387.4z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,371.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,371.7,430.9,371.5,430.9,371.2z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,371.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,371.7,416.4,371.5,416.4,371.2z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,371.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,371.7,401.9,371.5,401.9,371.2z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,371.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,371.7,485.4,371.5,485.4,371.2z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,371.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,371.7,470.9,371.5,470.9,371.2z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,371.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,371.7,456.4,371.5,456.4,371.2z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,351.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,352.1,485.4,351.9,485.4,351.6z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,351.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,352.1,470.9,351.9,470.9,351.6z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,351.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,352.1,456.4,351.9,456.4,351.6z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,332v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V332c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,332.5,430.9,332.3,430.9,332z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,332v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V332c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,332.5,416.4,332.3,416.4,332z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,332v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V332c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,332.5,401.9,332.3,401.9,332z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,332v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V332c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,332.5,470.9,332.3,470.9,332z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,332v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V332c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,332.5,456.4,332.3,456.4,332z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,315.8V304c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,316.3,430.9,316.1,430.9,315.8z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,315.8V304c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,316.3,416.4,316.1,416.4,315.8z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,315.8V304c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,316.3,401.9,316.1,401.9,315.8z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,315.8V304c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,316.3,485.4,316.1,485.4,315.8z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,315.8V304c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,316.3,470.9,316.1,470.9,315.8z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,315.8V304c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,316.3,456.4,316.1,456.4,315.8z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,533.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,533.8,430.9,533.6,430.9,533.3z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,533.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,533.8,416.4,533.6,416.4,533.3z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,533.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,533.8,401.9,533.6,401.9,533.3z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,533.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,533.8,485.4,533.6,485.4,533.3z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,533.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,533.8,470.9,533.6,470.9,533.3z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,533.3v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,533.8,456.4,533.6,456.4,533.3z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,500.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,501.4,430.9,501.1,430.9,500.9z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,500.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,501.4,416.4,501.1,416.4,500.9z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,500.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,501.4,401.9,501.1,401.9,500.9z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,500.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,501.4,485.4,501.1,485.4,500.9z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,500.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,501.4,470.9,501.1,470.9,500.9z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,500.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,501.4,456.4,501.1,456.4,500.9z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,549.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,550,430.9,549.8,430.9,549.5z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,549.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,550,416.4,549.8,416.4,549.5z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,549.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,550,401.9,549.8,401.9,549.5z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,549.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,550,485.4,549.8,485.4,549.5z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,549.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,550,470.9,549.8,470.9,549.5z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,549.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,550,456.4,549.8,456.4,549.5z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,517.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,517.6,430.9,517.4,430.9,517.1z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,517.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,517.6,416.4,517.4,416.4,517.1z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,517.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,517.6,401.9,517.4,401.9,517.1z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,517.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,517.6,485.4,517.4,485.4,517.1z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,517.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,517.6,470.9,517.4,470.9,517.1z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,517.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,517.6,456.4,517.4,456.4,517.1z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,484.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,485.2,430.9,484.9,430.9,484.7z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,484.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,485.2,416.4,484.9,416.4,484.7z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,484.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,485.2,401.9,484.9,401.9,484.7z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,484.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,485.2,485.4,484.9,485.4,484.7z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,484.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,485.2,470.9,484.9,470.9,484.7z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,484.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,485.2,456.4,484.9,456.4,484.7z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,565.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,566.2,430.9,566,430.9,565.7z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,565.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,566.2,416.4,566,416.4,565.7z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,565.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,566.2,470.9,566,470.9,565.7z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,565.7v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,566.2,456.4,566,456.4,565.7z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,595.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,596,430.9,595.8,430.9,595.5z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,595.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,596,416.4,595.8,416.4,595.5z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,595.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,596,401.9,595.8,401.9,595.5z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,595.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,596,485.4,595.8,485.4,595.5z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,595.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,596,470.9,595.8,470.9,595.5z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,595.5v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,596,456.4,595.8,456.4,595.5z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,611.7V600c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,612.2,430.9,612,430.9,611.7z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,611.7V600c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,612.2,416.4,612,416.4,611.7z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,611.7V600c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,612.2,401.9,612,401.9,611.7z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,611.7V600c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,612.2,485.4,612,485.4,611.7z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,611.7V600c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,612.2,470.9,612,470.9,611.7z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,611.7V600c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,612.2,456.4,612,456.4,611.7z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,627.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,628.5,430.9,628.2,430.9,627.9z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,627.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,628.5,416.4,628.2,416.4,627.9z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,627.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,628.5,401.9,628.2,401.9,627.9z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,627.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,628.5,485.4,628.2,485.4,627.9z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,627.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,628.5,470.9,628.2,470.9,627.9z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,627.9v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,628.5,456.4,628.2,456.4,627.9z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,676.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,677.1,430.9,676.8,430.9,676.6z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,676.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,677.1,416.4,676.8,416.4,676.6z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,676.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,677.1,401.9,676.8,401.9,676.6z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,676.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,677.1,485.4,676.8,485.4,676.6z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,676.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,677.1,470.9,676.8,470.9,676.6z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,676.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,677.1,456.4,676.8,456.4,676.6z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,692.8V681c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,693.3,430.9,693,430.9,692.8z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,692.8V681c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,693.3,416.4,693,416.4,692.8z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,692.8V681c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,693.3,401.9,693,401.9,692.8z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,692.8V681c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,693.3,485.4,693,485.4,692.8z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,692.8V681c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,693.3,470.9,693,470.9,692.8z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,692.8V681c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,693.3,456.4,693,456.4,692.8z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,644.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,644.7,430.9,644.4,430.9,644.1z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,644.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,644.7,416.4,644.4,416.4,644.1z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,644.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,644.7,401.9,644.4,401.9,644.1z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,644.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,644.7,470.9,644.4,470.9,644.1z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,644.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,644.7,485.4,644.4,485.4,644.1z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,644.1v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,644.7,456.4,644.4,456.4,644.1z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,660.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,660.9,430.9,660.6,430.9,660.4z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,660.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,660.9,416.4,660.6,416.4,660.4z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,660.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,660.9,401.9,660.6,401.9,660.4z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,660.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,660.9,485.4,660.6,485.4,660.4z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,660.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,660.9,470.9,660.6,470.9,660.4z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,660.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,660.9,456.4,660.6,456.4,660.4z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,709v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V709c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,709.5,430.9,709.2,430.9,709z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,709v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V709c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,709.5,416.4,709.2,416.4,709z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,709v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V709c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,709.5,401.9,709.2,401.9,709z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,709v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V709c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,709.5,485.4,709.2,485.4,709z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,709v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V709c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,709.5,470.9,709.2,470.9,709z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,709v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V709c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,709.5,456.4,709.2,456.4,709z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,725.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,725.7,430.9,725.5,430.9,725.2z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,725.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,725.7,416.4,725.5,416.4,725.2z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,725.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,725.7,401.9,725.5,401.9,725.2z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,725.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,725.7,485.4,725.5,485.4,725.2z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,725.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,725.7,470.9,725.5,470.9,725.2z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,725.2v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,725.7,456.4,725.5,456.4,725.2z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,741.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,741.9,430.9,741.7,430.9,741.4z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,741.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,741.9,416.4,741.7,416.4,741.4z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,741.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,741.9,401.9,741.7,401.9,741.4z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,741.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,741.9,485.4,741.7,485.4,741.4z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,741.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,741.9,470.9,741.7,470.9,741.4z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,741.4v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,741.9,456.4,741.7,456.4,741.4z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,757.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,758.1,430.9,757.9,430.9,757.6z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,757.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,758.1,416.4,757.9,416.4,757.6z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,757.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,758.1,401.9,757.9,401.9,757.6z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,757.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,758.1,485.4,757.9,485.4,757.6z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,757.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,758.1,470.9,757.9,470.9,757.6z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,757.6v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,758.1,456.4,757.9,456.4,757.6z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,773.8V762c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,774.3,430.9,774.1,430.9,773.8z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,773.8V762c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,774.3,416.4,774.1,416.4,773.8z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,773.8V762c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,774.3,401.9,774.1,401.9,773.8z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,773.8V762c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,774.3,485.4,774.1,485.4,773.8z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,773.8V762c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,774.3,470.9,774.1,470.9,773.8z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,773.8V762c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5v11.8c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,774.3,456.4,774.1,456.4,773.8z"/>
            <path opacity="0.125" fill="#737373" d="M430.9,790v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V790c0,0.3,0.2,0.5,0.5,0.5h11.8C430.6,790.5,430.9,790.3,430.9,790z"/>
            <path opacity="0.125" fill="#737373" d="M416.4,790v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V790c0,0.3,0.2,0.5,0.5,0.5h11.8C416.1,790.5,416.4,790.3,416.4,790z"/>
            <path opacity="0.125" fill="#737373" d="M401.9,790v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V790c0,0.3,0.2,0.5,0.5,0.5h11.8C401.6,790.5,401.9,790.3,401.9,790z"/>
            <path opacity="0.125" fill="#737373" d="M485.4,790v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V790c0,0.3,0.2,0.5,0.5,0.5h11.8C485.2,790.5,485.4,790.3,485.4,790z"/>
            <path opacity="0.125" fill="#737373" d="M470.9,790v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V790c0,0.3,0.2,0.5,0.5,0.5h11.8C470.7,790.5,470.9,790.3,470.9,790z"/>
            <path opacity="0.125" fill="#737373" d="M456.4,790v-11.8c0-0.3-0.2-0.5-0.5-0.5h-11.8 c-0.3,0-0.5,0.2-0.5,0.5V790c0,0.3,0.2,0.5,0.5,0.5h11.8C456.2,790.5,456.4,790.3,456.4,790z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('partition', 'business')">
            <path class="editable-content__background" fill="#C4C4C4" d="M431,165.3v-2c0-1.1-0.9-2-2-2h-37.8c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2H429 C430.1,167.3,431,166.4,431,165.3z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('partition', 'economy')">
            <path class="editable-content__background" fill="#C4C4C4" d="M431,293.7L431,293.7c0-0.7-0.6-1.3-1.3-1.3h-39.2c-0.7,0-1.3,0.6-1.3,1.3l0,0c0,0.7,0.6,1.3,1.3,1.3h39.2 C430.4,295,431,294.4,431,293.7z"/>
            <path class="editable-content__background" fill="#C4C4C4" d="M485.6,293.7L485.6,293.7c0-0.7-0.6-1.3-1.3-1.3H445c-0.7,0-1.3,0.6-1.3,1.3l0,0c0,0.7,0.6,1.3,1.3,1.3h39.2 C485,295,485.6,294.4,485.6,293.7z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('lav4all', 'front-section')">
            <path class="editable-content__background" fill="#C4C4C4" d="M485.6,119.8V138c0,1.1-0.9,2-2,2h-37.8c-1.1,0-2-0.9-2-2v-18.2c0-1.1,0.9-2,2-2h37.8 C484.7,117.8,485.6,118.7,485.6,119.8z"/>
            <path class="editable-content__icon" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M460.4,122.1c0.7,0,1.3,0.4,1.5,1c0.3,0.6,0.1,1.3-0.4,1.8 c-0.5,0.5-1.2,0.6-1.8,0.4c-0.6-0.2-1-0.8-1-1.5C458.8,122.8,459.5,122.1,460.4,122.1z M468.2,122.1c0.9,0,1.6,0.7,1.6,1.6 s-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6S467.2,122.1,468.2,122.1z M469.5,133.5c-0.1,0.7-0.2,1.5-0.3,2.2h-2.1 c-0.1-0.7-0.2-1.5-0.3-2.2h-2.4l1.8-4.3c-0.2-0.9-0.4-1.7-0.5-2.6h4.9c-0.2,0.9-0.4,1.7-0.5,2.6l1.8,4.3H469.5z M462.4,131.1 l-0.6,4.6H459l-0.6-4.6h-1c0.2-1.5,0.4-3.1,0.6-4.6h4.8c0.2,1.5,0.4,3.1,0.6,4.6H462.4z"/>
        </g>
        <path fill="#C4C4C4" d="M431,119.8V138c0,1.1-0.9,2-2,2h-37.8c-1.1,0-2-0.9-2-2v-18.2c0-1.1,0.9-2,2-2H429 C430.1,117.8,431,118.7,431,119.8z"/>
        <path fill="#FFFFFF" d="M401.9,132.4h15.5c0,0.9-0.7,1.6-1.6,1.6h-12.4C402.5,134,401.9,133.3,401.9,132.4L401.9,132.4z"/>
        <path fill="#FFFFFF" d="M404.9,124h9.2v6.1c0,0.8-0.7,1.5-1.5,1.5h-6c-0.4,0-0.8-0.2-1-0.4c-0.3-0.3-0.4-0.7-0.4-1v-0.5 c-0.8,0-1.5-0.2-2.1-0.8c-0.6-0.5-0.9-1.2-0.9-2C402.2,125.3,403.4,124.1,404.9,124z M405.2,128.4v-3.2h-0.3c-0.9,0-1.6,0.7-1.6,1.6 c0,0.5,0.2,1,0.6,1.3S404.7,128.5,405.2,128.4z"/>
        <path fill="#C4C4C4" d="M485.6,163.3v18.2c0,1.1-0.9,2-2,2h-37.8c-1.1,0-2-0.9-2-2v-18.2c0-1.1,0.9-2,2-2h37.8 C484.7,161.3,485.6,162.2,485.6,163.3z"/>
        <path fill="#FFFFFF" d="M456.5,175.9H472c0,0.9-0.7,1.6-1.6,1.6H458C457.1,177.5,456.4,176.8,456.5,175.9L456.5,175.9z"/>
        <path fill="#FFFFFF" d="M459.4,167.5h9.2v6.1c0,0.8-0.7,1.5-1.5,1.5h-6c-0.4,0-0.8-0.2-1-0.4c-0.3-0.3-0.4-0.7-0.4-1V173 c-0.8,0-1.5-0.2-2.1-0.8c-0.6-0.5-0.9-1.2-0.9-2C456.8,168.8,458,167.6,459.4,167.5z M459.8,171.9v-3.2h-0.3c-0.9,0-1.6,0.7-1.6,1.6 c0,0.5,0.2,1,0.6,1.3C458.8,171.9,459.3,172,459.8,171.9z"/>
        <g class="editable-content pulse-item" :class="contentClasses('moveableDivider', 'economy')">
            <path class="editable-content__background" fill="transparent" d="M430.9,389.6L430.9,389.6c0-0.7-0.6-1.3-1.3-1.3h-39.2c-0.7,0-1.3,0.6-1.3,1.3l0,0c0,0.7,0.6,1.3,1.3,1.3 h39.2C430.3,390.9,430.9,390.3,430.9,389.6z"/>
            <path class="editable-content__background" fill="transparent" d="M485.5,389.6L485.5,389.6c0-0.7-0.6-1.3-1.3-1.3h-39.3c-0.7,0-1.3,0.6-1.3,1.3l0,0c0,0.7,0.6,1.3,1.3,1.3 h39.2C484.9,390.9,485.5,390.3,485.5,389.6z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('centerCeilingStowage', 'front-section')">
            <path class="editable-content__background" fill="transparent" d="M433.6,250.8h6.8c0,0,0.1-0.1,0.1-0.2V209c0-0.1,0-0.2-0.1-0.2h-6.8c0,0-0.1,0.1-0.1,0.2l0,41.7  C433.5,250.8,433.5,250.8,433.6,250.8z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('centerCeilingStowage', 'middle-section')">
            <path class="editable-content__background" fill="transparent" d="M433.8,515.7h6.8c0,0,0.1-0.1,0.1-0.2v-41.7c0-0.1,0-0.2-0.1-0.2h-6.8c0,0-0.1,0.1-0.1,0.2v41.7  C433.7,515.7,433.7,515.7,433.8,515.7z"/>
        </g>
        <g class="editable-content pulse-item" :class="contentClasses('centerCeilingStowage', 'aft-section')">
            <path class="editable-content__background" fill="transparent" d="M434,769.8h6.8c0,0,0.1-0.1,0.1-0.2V728c0-0.1,0-0.2-0.1-0.2H434c0,0-0.1,0.1-0.1,0.2l0,41.7  C433.9,769.8,433.9,769.8,434,769.8z"/>
        </g>
    </svg>
</template>

<script>
import { defineComponent } from 'vue'
import { planeContentProps, usePlaneContentClass } from '@/composables/plane-content'

export default defineComponent({
  props: {
    ...planeContentProps()
  },
  setup (props) {
    const { contentClasses } = usePlaneContentClass(props)

    return { contentClasses }
  }
})
</script>
