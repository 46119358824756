<template>
    <div class="plane-blueprint">
        <div class="plane-blueprint__wrapper" ref="planeEl">
            <component :is="layout" :active-configuration="activeConfiguration" :active-feature-slug="activeFeatureSlug" />
            <div class="plane-blueprint__overlay" ref="overlayEl"></div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SingleAisleHighDensity from '@/components/planeblueprints/SingleAisleHighDensity.vue'
import SingleAisle2Class from '@/components/planeblueprints/SingleAisle2Class.vue'
import SingleAisle3Class from '@/components/planeblueprints/SingleAisle3Class.vue'

export default {
  components: {
    SingleAisleHighDensity,
    SingleAisle2Class,
    SingleAisle3Class
  },
  props: {
    layout: {
      type: String,
      default: ''
    },
    activeConfiguration: {
      type: Object,
      default: () => {}
    },
    activeFeatureSlug: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      pulseTimout: false
    }
  },

  computed: {
    ...mapGetters(['windowWidth'])
  },
  watch: {
    activeFeatureSlug () {
      setTimeout(() => {
        this.placePulseAnimations()
      }, 10)
    },
    windowWidth () {
      this.setPlanePosition()
    }
  },
  mounted () {
    this.setPlanePosition()
    setTimeout(() => {
      this.placePulseAnimations()
    }, 1000)
  },
  methods: {
    setPlanePosition () {
      this.$refs.planeEl.style.marginRight = null
      if (this.$refs.planeEl && this.$refs.planeEl.querySelector('.plane-body')) {
        const planeBodyEl = this.$refs.planeEl.querySelector('.plane-body')
        const planeBodyRect = planeBodyEl.getBoundingClientRect()
        if (planeBodyRect.left <= 50 && this.$refs.planeEl) {
          const negativeMarginRight = planeBodyRect.left >= 0 ? -50 : (planeBodyRect.left - 50)
          this.$refs.planeEl.style.marginRight = `${negativeMarginRight}px`
        }
      }
    },
    placePulseAnimations () {
      if (this.pulseTimout) {
        clearTimeout(this.pulseTimout)
        this.pulseTimout = false
      }
      if (this.$refs.planeEl) {
        this.$refs.overlayEl.innerHTML = ''
        const editableContents = this.$refs.planeEl.querySelectorAll('.pulse-item.is-active, .is-active .pulse-item')
        const planeRect = this.$refs.planeEl.getBoundingClientRect()
        editableContents.forEach(content => {
          const contentRect = content.getBoundingClientRect()
          const positionTop = contentRect.top - planeRect.top + (contentRect.height / 2)
          const positionRight = planeRect.width - (contentRect.left - planeRect.left) - (contentRect.width / 2)

          const positionTopPercentage = positionTop * 100 / planeRect.height
          const positionRightPercentage = positionRight * 100 / planeRect.width

          this.addPulseItem(positionTopPercentage, positionRightPercentage)
        })

        this.pulseTimout = setTimeout(() => {
          if (this.$refs.overlayEl) {
            this.$refs.overlayEl.innerHTML = ''
          }
        }, 2000 * 3) // one pulse takes 2s
      }
    },
    addPulseItem (positionTop, positionRight) {
      const el = document.createElement('div')
      el.innerHTML = '<span class="pulse__inner"></span>'
      el.classList.add('pulse')
      el.style.top = `${positionTop}%`
      el.style.right = `${positionRight}%`
      this.$refs.overlayEl.appendChild(el)
    }
  }
}
</script>
