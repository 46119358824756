<template>
   <g>
        <path d="M18.5,12.8c-0.5,0-0.8,0.3-0.8,0.8v6c0,0.3-0.2,0.6-0.3,0.9c-0.1,0.3-0.6,0.3-0.9,0.3H5.4
            c-0.3,0-0.6-0.2-0.9-0.3c-0.3-0.3-0.3-0.6-0.3-0.9V8.5c0-0.3,0.2-0.6,0.3-0.9c0.3-0.3,0.6-0.3,0.9-0.3h6c0.4,0,0.8-0.3,0.8-0.8
            c0-0.5-0.3-0.8-0.7-0.8h-6c-0.8,0-1.3,0.3-2,0.8C2.9,7,2.8,7.7,2.8,8.5v11c0,0.8,0.3,1.3,0.8,1.9s1.2,0.8,1.9,0.8h11
            c0.8,0,1.3-0.3,1.9-0.8c0.6-0.4,0.8-1.2,0.8-1.9v-6C19.2,13.1,19,12.8,18.5,12.8z"/>
        <path d="M22.2,3.2c-0.2-0.1-0.2-0.3-0.5-0.4c-0.1,0-0.1,0-0.3,0h-6c-0.5,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h4.2L10,14
            c-0.3,0.3-0.3,0.8,0,1c0.1,0.2,0.3,0.2,0.6,0.2s0.5,0,0.6-0.2l9.8-9.8v4.2c0,0.5,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8v-6
            C22.2,3.3,22.2,3.3,22.2,3.2z"/>
    </g>
</template>
