import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'

import 'element-plus/es/components/dialog/style/css'
import 'styles/index.scss'

import checkForTouch from '@/utils/check-for-touch'
import addResizeListener from '@/utils/window-resize'

checkForTouch()
addResizeListener()

createApp(App).use(store).use(router).mount('#app')
