import debounce from 'lodash/debounce'
import store from '@/store'

export default function addResizeListener () {
  window.addEventListener(
    'resize',
    debounce(() => {
      store.commit('setWindowWidth', window.innerWidth)
    }, 200)
  )
}
